import { TagsService } from '../../services';
import { AppThunk } from '../store';
import { tagsActions } from './tagsSlice';

export const createGlobalTagAction = (
  uid: string,
  label: string
): AppThunk => async dispatch => {
  const tag = await TagsService.createGlobalTag({ label });
  dispatch(tagsActions.acceptGlobalTags([tag]));
};
