import { digestSchedulesCollectionRef } from '../firebase/collection-refs';
import {
  firestoreTimeStampNow,
  firestoreToPlainObject,
  firestoreToPlainObjectMap
} from '../firebase/utils';
import { IDigestSchedule } from '../model';

interface AddScheduleOptions {
  deliverBy?: {
    sms: boolean;
    email: boolean;
  };
  time?: number;
  days?: number[];
  title?: string;
  tags?: string[];
}

const DEFAULT_DAYS = [0, 1, 2, 3, 4, 5, 6];
const DEFAULT_TITLE = 'Untitled';

export class DigestSchedulesService {
  static async getUserSchedules(uid: string): Promise<IDigestSchedule[]> {
    const querySnapshot = await digestSchedulesCollectionRef
      .where('uid', '==', uid)
      .get();

    return firestoreToPlainObjectMap(querySnapshot.docs);
  }

  static async getById(id: string): Promise<IDigestSchedule> {
    const documentSnapshot = await digestSchedulesCollectionRef.doc(id).get();
    return firestoreToPlainObject(documentSnapshot);
  }

  static async addSchedule(
    uid: string,
    {
      deliverBy = {
        email: true,
        sms: false
      },
      days = DEFAULT_DAYS,
      tags = [],
      time = 0,
      title = DEFAULT_TITLE
    }: AddScheduleOptions
  ): Promise<IDigestSchedule> {
    const documentReference = await digestSchedulesCollectionRef.add({
      enabled: true,
      uid,
      days,
      tags,
      time,
      title,
      deliverBy,
      timestamp: firestoreTimeStampNow()
    });

    return this.getById(documentReference.id);
  }

  static async updateSchedule(
    id: string,
    data: Partial<IDigestSchedule>
  ): Promise<void> {
    await digestSchedulesCollectionRef.doc(id).update(data);
  }

  static async deleteSchedule(id: string): Promise<void> {
    await digestSchedulesCollectionRef.doc(id).delete();
  }
}
