import { useSelector } from 'react-redux';

import { RootState } from '../rootReducer';

export const useDigestItemEditing = (id?: string): boolean => {
  return useSelector<RootState, boolean>(state => {
    if (!id) return false;
    return state.digest.editingIds.includes(id);
  });
};
