import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';

import { IModelBase } from '../../model';
import { getCollectionItems } from './getCollectionItems';
import { ICollectionState } from './ICollectionState';

interface Options<T> {
  sortingKey?: keyof T;
  sortingOrder?: 'desc' | 'asc';
}
export const acceptCollectionStateData = <TData extends IModelBase>(
  state: ICollectionState<TData>,
  data: TData[],
  { sortingKey, sortingOrder }: Options<TData> = {}
): void => {
  state.loaded = true;
  state.loading = false;

  if (sortingKey && sortingOrder) {
    const allData = uniqBy([...getCollectionItems(state), ...data], 'id');
    const sorted = orderBy(allData, sortingKey, sortingOrder);

    state.ids = [];

    sorted.forEach(d => {
      if (!d || !d.id) {
        debugger;
        throw new Error(`empty id`);
      }
      state.ids.push(d.id);
      if (!state.byId[d.id]) state.byId[d.id] = d;
    });
  } else {
    data.forEach(d => {
      if (d.id && !state.ids.includes(d.id)) {
        state.byId[d.id] = d;
        state.ids.push(d.id);
      }
    });
  }
};
