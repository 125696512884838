import { IQuote, IQuoteToList } from '../../model';
import { quotesActions } from '../quotes';
import { quotesListsActions } from '../quotesLists';
import { quotesToListsSlice } from '../quotesToLists';
import { AppThunk } from '../store';

export const importCSVToListAction = (
  uid: string,
  listId: string,
  file: File
): AppThunk => async dispatch => {
  dispatch(quotesListsActions.startImport(listId));

  const formData = new FormData();
  formData.append('listId', listId);
  formData.append('uid', uid);
  formData.append('file', file);

  const res = await fetch(
    // 'http://localhost:5001/selftalk-dev/us-central1/csvQuotesImport',
    'https://us-central1-selftalk-dev.cloudfunctions.net/csvQuotesImport',
    {
      method: 'POST',
      body: formData
    }
  );

  const data = await res.json();
  const results = data.createQuotesResults as {
    quote: IQuote;
    quoteToList: IQuoteToList;
  }[];

  const quotes = results.map(r => r.quote);
  const quotesToLists = results.map(r => r.quoteToList);

  dispatch(quotesActions.acceptData(quotes));
  dispatch(quotesListsActions.completeImport(listId));
  dispatch(quotesToListsSlice.actions.acceptItems(quotesToLists));

  // setTimeout(() => location.reload(), 250);
};
