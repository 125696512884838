import { quotesListsCollectionRef } from '../firebase/collection-refs';
import {
  firestoreTimeStampNow,
  firestoreToPlainObject
} from '../firebase/utils';
import { IQuotesList } from '../model';

export class QuotesListsService {
  static async ensureDefaultList(uid: string): Promise<void> {
    const querySnapshot = await quotesListsCollectionRef
      .where('isDefault', '==', true)
      .where('uid', '==', uid)
      .get();

    if (querySnapshot.docs.length > 0) return;

    await this.createDefaultList(uid);
  }

  static async getUserLists(uid: string): Promise<IQuotesList[]> {
    const querySnapshot = await quotesListsCollectionRef
      .where('uid', '==', uid)
      .get();

    return querySnapshot.docs.map(d => firestoreToPlainObject(d));
  }

  //  ------------------------------------

  private static async createDefaultList(uid: string): Promise<void> {
    await quotesListsCollectionRef.add({
      uid,
      isDefault: true,
      quotes: [],
      title: 'Default List',
      timestamp: firestoreTimeStampNow()
    });
  }
}
