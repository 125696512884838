import { emailRecipientsCollectionRef } from '../firebase/collection-refs';
import { firestoreTimeStampNow } from '../firebase/utils';

export class EmailRecipientsService {
  static async ensureRecipientForAUser(
    uid: string,
    email: string
  ): Promise<void> {
    const documentSnapshot = await emailRecipientsCollectionRef.doc(uid).get();

    if (documentSnapshot.exists) return;

    await emailRecipientsCollectionRef.doc(uid).set({
      email,
      timestamp: firestoreTimeStampNow()
    });
  }
}
