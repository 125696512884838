import Chip from '@material-ui/core/Chip/Chip';
import EditIcon from '@material-ui/icons/Edit';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import React, { FC } from 'react';

interface TagProps {
  id?: string;
  onClick?: () => void;
  onEdit?: () => void;
  isSelected: boolean;
  label: string;
  className?: string;
  size?: 'small' | 'medium';
  disabled?: boolean;
  hideIcon?: boolean;
  allowEdit?: boolean;
}

export const Tag: FC<TagProps> = ({
  onClick,
  onEdit,
  isSelected,
  label,
  className = '',
  size = 'medium',
  disabled = false,
  hideIcon = false,
  allowEdit = false
}) => {
  return (
    <Chip
      size={size}
      disabled={disabled}
      icon={hideIcon ? undefined : <LocalOfferIcon fontSize={'small'} />}
      className={className}
      color={isSelected ? 'secondary' : 'default'}
      label={label}
      onClick={onClick}
      onDelete={allowEdit ? onEdit : undefined}
      deleteIcon={allowEdit ? <EditIcon fontSize={'small'} /> : undefined}
    />
  );
};
