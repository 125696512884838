import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import React, { FC } from 'react';

import { IDigestSchedule } from '../../model';
import { DigestScheduleViewTags } from './DigestScheduleViewTags';

const toHours = (minutes: number): number => {
  return minutes / 60;
};

interface DigestScheduleViewProps {
  data: IDigestSchedule;
}

export const DigestScheduleView: FC<DigestScheduleViewProps> = ({ data }) => {
  const { sms, email } = data.deliverBy || { email: true };
  return (
    <CardContent>
      <DigestScheduleViewTags selectedIds={data.tags} />
      <Typography variant="body2" color="textSecondary" component="p">
        Deliver every day at {toHours(data.time)}:00 by {sms && 'SMS'}
        {sms && email && ' & '}
        {email && 'Email'}
      </Typography>
    </CardContent>
  );
};
