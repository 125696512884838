import { useSelector } from 'react-redux';

import { RootState } from '../../rootReducer';
import { UserSettingsSliceState } from '../userSettingsSlice';

export const useUserSettings = (): UserSettingsSliceState => {
  return useSelector<RootState, UserSettingsSliceState>(
    state => state.userSettings
  );
};
