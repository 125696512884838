import { createContext, Dispatch, SetStateAction } from 'react';

export interface InfiniteScrollContextValue {
  page: number;
  hasMore: boolean;
  setHasMore: Dispatch<SetStateAction<boolean>>;
  reset: () => void;
}

const initial: InfiniteScrollContextValue = {
  page: 0,
  hasMore: false,
  setHasMore: () => {
    throw new Error('InfiniteScrollContext: Must provide a value');
  },
  reset: () => {
    return;
  }
};

export const InfiniteScrollContext = createContext<InfiniteScrollContextValue>(
  initial
);
