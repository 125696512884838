import Container from '@material-ui/core/Container';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC } from 'react';

import { DigestSchedule } from './DigestSchedule';
import { UserSettings } from './UserSettings';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingBottom: theme.spacing(4)
    }
  })
);

interface SettingsPageProps {}

export const SettingsPage: FC<SettingsPageProps> = () => {
  const classes = useStyles();
  return (
    <Container maxWidth={'md'} className={classes.container}>
      <UserSettings />
      <DigestSchedule />
    </Container>
  );
};
