import React, { FC } from 'react';

import {
  InfiniteScrollContext,
  InfiniteScrollContextValue
} from './InfiniteScrollContext';

interface InfiniteScrollProviderProps {
  value: InfiniteScrollContextValue;
}

export const InfiniteScrollProvider: FC<InfiniteScrollProviderProps> = ({
  children,
  value
}) => {
  return (
    <InfiniteScrollContext.Provider value={value}>
      {children}
    </InfiniteScrollContext.Provider>
  );
};
