import { getFirebase } from '../../firebase';
import { AppThunk } from '../store';
import { authActions } from './authSlice';

export const signUpWithEmailAndPasswordAction = (
  email: string,
  password: string
): AppThunk => async dispatch => {
  try {
    const result = await getFirebase()
      .auth()
      .createUserWithEmailAndPassword(email, password);

    if (!result.user) {
      throw new Error('user object is empty');
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log({ err: err.toString() });
    dispatch(
      authActions.setAuthenticated({ isAuthenticated: false, user: null })
    );
  }
};
