import { QuotesService, QuotesToListsService } from '../../services';
import { quotesActions } from '../quotes';
import { quotesToListsSlice } from '../quotesToLists';
import { AppThunk } from '../store';
import { viewActions } from '../view';

export const createQuoteInCurrentListAction = (
  text: string,
  author: string,
  isPublic: boolean
): AppThunk => async (dispatch, getState) => {
  const state = getState();

  const selectedListId = state.quotesLists.selected;
  const uid = state.auth.user?.uid;

  if (!uid) throw new Error('user is not authenticated');
  if (!selectedListId) throw new Error('no list selected');

  const quote = await QuotesService.create(uid, text, author, isPublic);

  if (!quote?.id) {
    throw new Error('Created Quote id is empty!');
  }

  dispatch(quotesActions.acceptData([quote]));
  dispatch(quotesActions.setQuoteLikedState({ liked: true, quote: quote.id }));
  dispatch(viewActions.setNewQuoteOpenState(false));
  dispatch(viewActions.setNewNoteOpenState(false));

  const quoteToList = await QuotesToListsService.addQuote(
    uid,
    quote.id as string,
    selectedListId
  );
  dispatch(quotesToListsSlice.actions.acceptItems([quoteToList]));
};
