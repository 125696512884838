// import orderBy from 'lodash/orderBy';
import { useSelector } from 'react-redux';

import { IQuote } from '../../model';
import { RootState } from '../rootReducer';
import { getCollectionItems } from '../utils';

export const useAllPublicQuotes = (): IQuote[] => {
  return useSelector<RootState, IQuote[]>(state => {
    return getCollectionItems(state.quotes.collection).filter(q => q.isPublic);
  });
};
