import React, { FC, Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { IQuote } from '../../model';
import {
  checkLikedStateAction,
  checkTaggedStateAction
} from '../../redux/commonActions';
import { QuoteCard } from '../QuoteCard';

interface QuotesListProps {
  quotes: IQuote[];
}

export const QuotesList: FC<QuotesListProps> = ({ quotes }) => {
  const dispatch = useDispatch();

  const idsString = quotes.map(q => q.id).join('');

  useEffect(() => {
    const ids = quotes.map(q => q.id as string);
    dispatch(checkLikedStateAction(ids));
    dispatch(checkTaggedStateAction(ids));
  }, [idsString]);

  return (
    <Fragment>
      {quotes.map(quote => (
        <QuoteCard quote={quote} key={`${quote.id}`} />
      ))}
    </Fragment>
  );
};
