import { useSelector } from 'react-redux';

import { IQuotesList } from '../../model';
import { RootState } from '../rootReducer';

export const useSelectedList = (): IQuotesList | null => {
  return useSelector<RootState, IQuotesList | null>(state => {
    const { selected, collection } = state.quotesLists;
    if (!selected) return null;
    return collection.byId[selected] || null;
  });
};
