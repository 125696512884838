import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { createQuoteInCurrentListAction } from '../../redux/commonActions';
import { useNewNoteViewOpened } from '../../redux/selectors';
import { viewActions } from '../../redux/view';
import { Transition } from '../Animations';
import { QuoteForm, QuoteFormData } from '../QuoteForm';

const useStyles = makeStyles(theme =>
  createStyles({
    dialog: {
      marginBottom: theme.spacing(5)
    }
  })
);

interface AddNoteDialogProps {}

export const AddNoteDialog: FC<AddNoteDialogProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isOpen = useNewNoteViewOpened();

  const handleClose = useCallback(() => {
    dispatch(viewActions.setNewNoteOpenState(false));
  }, []);

  const onSubmit = useCallback((data: QuoteFormData) => {
    const { text } = data;
    if (!text) return;
    dispatch(createQuoteInCurrentListAction(text, 'Self Note', false));
    dispatch(viewActions.setNewNoteOpenState(false));
  }, []);

  return (
    <Dialog
      maxWidth={'sm'}
      fullWidth
      className={classes.dialog}
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <DialogContent>
        <QuoteForm
          onSubmit={onSubmit}
          disableFields={{ isPublic: true, author: true }}
        />
      </DialogContent>
    </Dialog>
  );
};
