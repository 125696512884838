import Fab from '@material-ui/core/Fab/Fab';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { viewActions } from 'src/redux/view';

interface FloatingAddQuoteButtonProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: 'fixed',
      right: theme.spacing(4),
      bottom: theme.spacing(12)
    },
    icon: {
      marginRight: theme.spacing(1)
    }
  })
);

export const FloatingAddNoteButton: FC<FloatingAddQuoteButtonProps> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const openAddQuoteView = useCallback(() => {
    dispatch(viewActions.setNewNoteOpenState(true));
  }, []);
  return (
    <Fab
      size={'large'}
      variant={'extended'}
      onClick={openAddQuoteView}
      className={classes.fab}
      color={'secondary'}
    >
      <AddIcon className={classes.icon} />
      Create Note
    </Fab>
  );
};
