import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField/TextField';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';

import { IQuote } from '../../model';

export type QuoteFormData = Pick<IQuote, 'text' | 'author' | 'isPublic'>;

interface QuoteFormProps {
  data?: Partial<IQuote>;
  onSubmit: (data: QuoteFormData) => void;
  disableFields?: Partial<Record<keyof QuoteFormData, boolean>>;
}

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column'
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3)
    },
    submit: {
      margin: theme.spacing(3, 0, 2)
    }
  })
);

export const QuoteForm: FC<QuoteFormProps> = ({
  data = {},
  onSubmit,
  disableFields
}) => {
  const classes = useStyles();

  const { register, handleSubmit } = useForm<QuoteFormData>({
    defaultValues: data
  });

  const submitHandler = handleSubmit(({ text, author, isPublic }) => {
    if (!text && !disableFields?.text) return;
    if (!author && !disableFields?.author) return;
    onSubmit({ text, author, isPublic });
  });

  return (
    <Container component={'section'} maxWidth={'lg'} className={classes.paper}>
      <form onSubmit={submitHandler}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              inputRef={register}
              aria-label={'Quote Text'}
              variant="outlined"
              required
              fullWidth
              multiline
              id="text"
              label="Text"
              name="text"
            />
          </Grid>

          {!disableFields?.author && (
            <Grid item xs={12}>
              <TextField
                inputRef={register}
                aria-label={'Quote Author'}
                variant="outlined"
                required
                fullWidth
                multiline
                id="author"
                label="Author"
                name="author"
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Grid container justify="flex-end" alignItems={'center'}>
              <Grid item xs={6}>
                {!disableFields?.isPublic && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputRef={register}
                        name="isPublic"
                        color="primary"
                      />
                    }
                    label="Public"
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};
