import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { useSelector } from 'react-redux';

import { IQuote } from '../../model';
import { RootState } from '../rootReducer';
import { getCollectionItems, getCollectionStateItem } from '../utils';
import { useAuthenticatedUID } from './useAuthenticatedUID';
import { useSelectedTags } from './useSelectedTags';

export const useTaggedQuotes = (): IQuote[] => {
  const uid = useAuthenticatedUID();
  const selectedTagsIds = useSelectedTags();

  return useSelector<RootState, IQuote[]>(state => {
    if (!uid) return [];
    if (!selectedTagsIds.length) return [];

    const items = getCollectionItems(state.quotesToTags.collection);
    const relevantItems = items.filter(i => selectedTagsIds.includes(i.tag));
    const sortedItems = orderBy(relevantItems, ['quoteTimestamp'], 'desc');

    return uniqBy(sortedItems, 'quote')
      .map(({ quote }) =>
        getCollectionStateItem(state.quotes.collection, quote)
      )
      .filter(Boolean) as IQuote[];
  }, isEqual);
};
