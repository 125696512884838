import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IDigestSchedule } from '../../model';
import {
  acceptCollectionStateData,
  createCollectionState,
  ICollectionState,
  removeFromCollectionState,
  setCollectionItemLoading,
  updateCollectionStateItem
} from '../utils';

interface DigestCollection {
  collection: ICollectionState<IDigestSchedule>;
}

interface DigestEditing {
  editingIds: string[];
}

export type DigestState = DigestCollection & DigestEditing;

const initialState: DigestState = {
  collection: createCollectionState<IDigestSchedule>(),
  editingIds: []
};

interface UpdateSchedulePayload {
  id: string;
  data: Partial<IDigestSchedule>;
}

interface SetScheduleItemLoadingPayload {
  id: string;
  loading: boolean;
}

interface RemoveScheduleItemPayload {
  id: string;
}

interface SetEditingPayload {
  id: string;
  editing: boolean;
}

const digestSlice = createSlice({
  name: 'digest',
  initialState,
  reducers: {
    acceptSchedules(state, action: PayloadAction<IDigestSchedule[]>) {
      acceptCollectionStateData(state.collection, action.payload);
    },

    setScheduleItemLoading(
      state,
      action: PayloadAction<SetScheduleItemLoadingPayload>
    ) {
      const { id, loading } = action.payload;
      setCollectionItemLoading(state.collection, id, loading);
    },

    updateSchedule(state, action: PayloadAction<UpdateSchedulePayload>) {
      const { data, id } = action.payload;
      updateCollectionStateItem(state.collection, id, data);
    },

    removeSchedule(state, action: PayloadAction<RemoveScheduleItemPayload>) {
      const { id } = action.payload;
      removeFromCollectionState(state.collection, id);
    },

    setEditing(state, action: PayloadAction<SetEditingPayload>) {
      const { id, editing } = action.payload;
      const index = state.editingIds.indexOf(id);
      if (editing && index === -1) state.editingIds.push(id);
      if (!editing && index !== -1) state.editingIds.splice(index, 1);
    }
  }
});

export const { reducer: digestReducer, actions: digestActions } = digestSlice;
