import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { APP_ROUTES_CONFIG } from '../../appRoutesConfig';
import { SignIn } from './SignIn';
import { SignUp } from './SignUp';

interface AuthPageRoutesProps {}

export const AuthPageRoutes: FC<AuthPageRoutesProps> = () => {
  return (
    <Switch>
      <Route path={APP_ROUTES_CONFIG.AUTH_SIGN_UP}>
        <SignUp />
      </Route>
      <Route path={APP_ROUTES_CONFIG.AUTH_SIGN_IN}>
        <SignIn />
      </Route>

      <Redirect to={APP_ROUTES_CONFIG.AUTH_SIGN_IN} />
    </Switch>
  );
};
