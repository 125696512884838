import firebase from 'firebase/app';
import flow from 'lodash/flow';
import mapValues from 'lodash/mapValues';

import { IModelBase } from '../../model';

const transformTimestamps = (value: unknown): unknown => {
  return value instanceof firebase.firestore.Timestamp
    ? value.toDate().toISOString()
    : value;
};

export const firestoreToPlainObject = <T extends IModelBase>(
  documentSnapshot: firebase.firestore.DocumentSnapshot<T>
): T => {
  const data = documentSnapshot.data();

  if (!data) throw new Error('unexpected empty data');

  const transform = flow([transformTimestamps]);

  return {
    ...mapValues(data, transform),
    id: documentSnapshot.id
  };
};
