import chunk from 'lodash/chunk';

import { quotesToListsRef } from '../firebase/collection-refs/quotesToListsRef';
import {
  firestoreConvertJSDate,
  firestoreTimeStampNow,
  firestoreToPlainObject,
  firestoreToPlainObjectMap
} from '../firebase/utils';
import { IQuoteToList } from '../model';
import { QuotesService } from './QuotesService';

interface FindFilter {
  list: string;
}

interface FindOptions {
  lastTimestamp?: string | null;
}

export class QuotesToListsService {
  static async checkAddedState(
    uid: string,
    quotes: string[]
  ): Promise<Record<string, boolean>> {
    if (!quotes.length) return {};

    const quotesChunks = chunk(quotes, 10);

    const resultsChunks = await Promise.all(
      quotesChunks.map(async quotesChunk => {
        const snapshot = await quotesToListsRef
          .where('uid', '==', uid)
          .where('quote', 'in', quotesChunk)
          .get();
        return snapshot.docs.map(d => d.data().quote);
      })
    );

    const addedQuoteIds = resultsChunks.flat();

    return quotes.reduce((res, id) => {
      res[id] = addedQuoteIds.includes(id);
      return res;
    }, {} as Record<string, boolean>);
  }

  static async find(
    filter: FindFilter,
    options: FindOptions
  ): Promise<IQuoteToList[]> {
    const { list } = filter;
    const { lastTimestamp } = options;

    const firestoreTimestamp = firestoreConvertJSDate(lastTimestamp);

    const snapshot = await quotesToListsRef
      .where('list', '==', list)
      .orderBy('quoteTimestamp', 'desc')
      .startAfter(firestoreTimestamp)
      .limit(10)
      .get();

    return firestoreToPlainObjectMap(snapshot.docs);
  }

  static async addQuote(
    uid: string,
    quote: string,
    list: string
  ): Promise<IQuoteToList> {
    const quoteData = await QuotesService.getById(quote);
    const reference = await quotesToListsRef.add({
      timestamp: firestoreTimeStampNow(),
      quoteTimestamp: firestoreConvertJSDate(quoteData.timestamp.toString()),
      uid,
      quote,
      list
    });

    const snapshot = await reference.get();
    return firestoreToPlainObject(snapshot);
  }

  static async removeQuote(quote: string, list: string): Promise<string> {
    const quoteToLists = await quotesToListsRef
      .where('quote', '==', quote)
      .where('list', '==', list)
      .get();

    if (quoteToLists.size !== 1) {
      throw new Error(`unexpected size ${quoteToLists.size}`);
    }

    await this.remove(quoteToLists.docs[0].id);

    return quoteToLists.docs[0].id;
  }

  static async remove(id: string): Promise<void> {
    await quotesToListsRef.doc(id).delete();
  }
}
