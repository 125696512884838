import Slide from '@material-ui/core/Slide/Slide';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import React, { forwardRef, ReactElement, Ref } from 'react';

export const Transition = forwardRef(
  (
    props: TransitionProps & { children?: ReactElement<unknown, any> },
    ref: Ref<unknown>
  ) => {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

Transition.displayName = 'Transition';
