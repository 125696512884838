import Box from '@material-ui/core/Box/Box';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Container from '@material-ui/core/Container/Container';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography/Typography';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useInfiniteScrollContext } from '../../components/InfiniteScroll';
import { ListCsvImport } from '../../components/ListCSVImport';
import { PageTitle } from '../../components/PageTitle';
import { QuotesList } from '../../components/QuotesList';
import { loadMoreListQuotesAction } from '../../redux/commonActions';
import { quotesActions } from '../../redux/quotes';
import {
  useAuthenticatedUID,
  useAuthIsAdmin,
  useListQuotesAllLoaded,
  useSelectedList,
  useSelectedListQuotes
} from '../../redux/selectors';

const useStyles = makeStyles(theme =>
  createStyles({
    titleRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    listEnd: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    loaderHolder: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  })
);

interface MyListPageProps {}

export const MyListPage: FC<MyListPageProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const uid = useAuthenticatedUID();
  const isAdmin = useAuthIsAdmin();

  const selectedList = useSelectedList();

  const quotes = useSelectedListQuotes();
  const allLoaded = useListQuotesAllLoaded(selectedList?.id);

  const { page, setHasMore, hasMore, reset } = useInfiniteScrollContext();

  useEffect(() => {
    dispatch(quotesActions.resetQuotesCollection());
  }, []);

  useEffect(() => {
    if (!uid) return;
    if (!selectedList || !selectedList.id) return;
    if (allLoaded) return;

    dispatch(loadMoreListQuotesAction(selectedList.id));
  }, [uid, selectedList, page, allLoaded]);

  useEffect(() => {
    if (!allLoaded) reset();
  }, [allLoaded]);

  useEffect(() => {
    setHasMore(!allLoaded);
  }, [allLoaded]);

  return (
    <Container maxWidth={'md'}>
      <Box className={classes.titleRow}>
        <PageTitle text={'My List'} />
        {isAdmin && <ListCsvImport />}
      </Box>
      <QuotesList quotes={quotes} />

      {!hasMore && (
        <Box className={classes.listEnd}>
          <Typography variant={'subtitle2'}>
            Well Done! You&apos;ve scrolled to the end!
          </Typography>
        </Box>
      )}

      {hasMore && (
        <Box className={classes.loaderHolder}>
          <CircularProgress color="secondary" disableShrink />
        </Box>
      )}
    </Container>
  );
};
