import Button from '@material-ui/core/Button/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { FC, Fragment, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { importCSVToListAction } from '../../redux/commonActions';
import {
  useAuthenticatedUID,
  useIsQuotesListImporting,
  useSelectedListId
} from '../../redux/selectors';

interface ListCsvImportProps {}

export const ListCsvImport: FC<ListCsvImportProps> = () => {
  const dispatch = useDispatch();
  const [, forceRerender] = useState({});
  const listId = useSelectedListId();
  const uid = useAuthenticatedUID();
  const isImporting = useIsQuotesListImporting(listId);

  const changeHandler = useCallback(
    event => {
      const file = event.target.files[0];
      if (!file) return;
      if (!listId) return;
      if (!uid) return;
      if (isImporting) return;

      dispatch(importCSVToListAction(uid, listId, file));

      forceRerender({});
    },
    [listId, uid, isImporting]
  );

  return (
    <Fragment>
      <input
        disabled={isImporting}
        value={[]}
        onChange={changeHandler}
        accept="text/csv"
        type={'file'}
        id={'ListCsvImportInput'}
        style={{ display: 'none' }}
      />
      <label htmlFor={'ListCsvImportInput'}>
        <Button
          disabled={isImporting}
          color={'secondary'}
          variant={'contained'}
          component="div"
        >
          {isImporting && (
            <CircularProgress size={15} style={{ marginRight: 15 }} />
          )}
          Upload CSV
        </Button>
      </label>
    </Fragment>
  );
};
