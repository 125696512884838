import { QuotesToListsService } from '../../services';
import { quotesActions } from '../quotes';
import { quotesToListsSlice } from '../quotesToLists';
import { AppThunk } from '../store';

export const removeQuoteFromListAction = (
  listId: string,
  quoteId: string
): AppThunk => async dispatch => {
  const removedId = await QuotesToListsService.removeQuote(quoteId, listId);
  dispatch(quotesToListsSlice.actions.removeById(removedId));
  dispatch(quotesActions.setQuoteLikedState({ quote: quoteId, liked: false }));
};
