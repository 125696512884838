import { QuotesToListsService } from '../../services';
import { quotesActions } from '../quotes';
import { quotesToListsSlice } from '../quotesToLists';
import { AppThunk } from '../store';

export const addQuoteToListAction = (
  uid: string,
  listId: string,
  quoteId: string
): AppThunk => async dispatch => {
  const quoteToList = await QuotesToListsService.addQuote(uid, quoteId, listId);
  dispatch(quotesToListsSlice.actions.acceptItems([quoteToList]));
  dispatch(quotesActions.setQuoteLikedState({ quote: quoteId, liked: true }));
};
