import { IUserSettings } from '../../model';
import { UserSettingsService } from '../../services';
import { AppThunk } from '../store';
import { userSettingsSlice } from './userSettingsSlice';

export const saveUserSettingsAction = (
  changes: Pick<IUserSettings, 'firstName' | 'lastName' | 'phone' | 'timezone'>
): AppThunk => async (dispatch, getState) => {
  const state = getState();

  const uid = state.auth.user?.uid;
  if (!uid) return;

  dispatch(userSettingsSlice.actions.setLoading(true));

  try {
    await UserSettingsService.updateUserSettings(uid, changes);
    dispatch(userSettingsSlice.actions.setSettings(changes));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(error);
  } finally {
    dispatch(userSettingsSlice.actions.setLoading(false));
  }
};
