import chunk from 'lodash/chunk';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';

import { quotesToTagsRef } from '../firebase/collection-refs/quotesToTagsRef';
import {
  firestoreConvertJSDate,
  firestoreTimeStampNow,
  firestoreToPlainObject,
  firestoreToPlainObjectMap
} from '../firebase/utils';
import { IQuoteToTag } from '../model';

interface FindFilter {
  uid: string;
  tags: string[];
}

interface FindOptions {
  lastTimestamp: Date;
}

export class QuotesToTagsService {
  static async checkTaggedState(
    uid: string,
    quotes: string[]
  ): Promise<Record<string, string[]>> {
    if (!quotes.length) return {};
    const quotesChunks = chunk(quotes, 10);

    const resultsChunks: { quote: string; tag: string }[][] = await Promise.all(
      quotesChunks.map(async quotesChunk => {
        const snapshot = await quotesToTagsRef
          .where('uid', '==', uid)
          .where('quote', 'in', quotesChunk)
          .get();

        return snapshot.docs
          .map(doc => doc.data())
          .map(({ quote, tag }) => ({ quote, tag }));
      })
    );

    const allResults: { quote: string; tag: string }[] = resultsChunks.flat();

    const byQuoteId: Record<string, { quote: string; tag: string }[]> = groupBy(
      allResults,
      'quote'
    );

    return mapValues(byQuoteId, values => values.map(v => v.tag));
  }

  static async removeById(id: string): Promise<void> {
    await quotesToTagsRef.doc(id).delete();
  }

  static async unTagQuote(
    uid: string,
    tag: string,
    quote: string
  ): Promise<void> {
    const snapshot = await quotesToTagsRef
      .where('uid', '==', uid)
      .where('tag', '==', tag)
      .where('quote', '==', quote)
      .get();

    if (snapshot.size > 0) await snapshot.docs[0].ref.delete();
  }

  static async tagQuote(
    uid: string,
    tag: string,
    quote: string,
    quoteTimestamp: string
  ): Promise<IQuoteToTag> {
    const reference = await quotesToTagsRef.add({
      quote,
      tag,
      uid,
      quoteTimestamp: firestoreConvertJSDate(quoteTimestamp),
      timestamp: firestoreTimeStampNow()
    });

    const snapshot = await reference.get();
    return firestoreToPlainObject(snapshot);
  }

  static async findByTags(
    filter: FindFilter,
    options: FindOptions
  ): Promise<IQuoteToTag[]> {
    const { tags, uid } = filter;
    const { lastTimestamp } = options;

    const firestoreTimestamp = firestoreConvertJSDate(lastTimestamp);

    const snapshot = await quotesToTagsRef
      .where('uid', '==', uid)
      .where('tag', 'in', tags)
      .orderBy('quoteTimestamp', 'desc')
      .startAfter(firestoreTimestamp)
      .limit(10)
      .get();

    return firestoreToPlainObjectMap(snapshot.docs);
  }
}
