import Box from '@material-ui/core/Box/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Tag } from '../../components/Tag';
import {
  useAuthenticatedUID,
  useAuthIsAdmin,
  useGlobalTags,
  useSelectedTags
} from '../../redux/selectors';
import {
  createGlobalTagAction,
  deleteGlobalTagAction,
  tagsActions,
  updateTagLabelAction
} from '../../redux/tags';
import { TagEditDialog } from './TagEditDialog';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginBottom: theme.spacing(3)
    },
    chip: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  })
);

interface EditableTag {
  id?: string;
  label?: string;
}

interface TagsCloudProps {}

export const TagsCloud: FC<TagsCloudProps> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isAdmin = useAuthIsAdmin();
  const uid = useAuthenticatedUID();
  const [editTagModalOpen, setEditTagModalOpen] = useState(false);
  const [editableTag, setEditableTag] = useState<EditableTag>({});

  const tags = useGlobalTags();
  const selectedTagsIds = useSelectedTags();

  const isSelected = (id: string): boolean => selectedTagsIds.includes(id);

  const toggleTagSelect = useCallback(
    (id: string): void => {
      if (selectedTagsIds.includes(id)) {
        dispatch(tagsActions.unSelectTag(id));
      } else {
        dispatch(tagsActions.selectTag(id));
      }
    },
    [selectedTagsIds]
  );

  const openEditTagDialog = useCallback((id: string, label: string) => {
    setEditableTag({ id, label });
    setEditTagModalOpen(true);
  }, []);

  const openCreateTagDialog = useCallback(() => {
    setEditableTag({});
    setEditTagModalOpen(true);
  }, []);

  const onCancelEdit = useCallback(() => {
    setEditTagModalOpen(false);
    setEditableTag({});
  }, []);

  const onTagSave = useCallback(
    (label: string) => {
      if (editableTag.id) {
        dispatch(updateTagLabelAction(editableTag.id, label));
      } else {
        if (!uid) throw new Error('unexpected empty uid');
        dispatch(createGlobalTagAction(uid, label));
      }
      setEditTagModalOpen(false);
      setEditableTag({});
    },
    [editableTag.id, uid]
  );

  const onTagDelete = useCallback(() => {
    if (!editableTag.id) return;

    dispatch(deleteGlobalTagAction(editableTag.id));

    setEditTagModalOpen(false);
    setEditableTag({});
  }, [editableTag.id]);

  return (
    <Box className={classes.root}>
      <TagEditDialog
        createMode={!editableTag.id}
        label={editableTag.label || ''}
        open={editTagModalOpen}
        onCancel={onCancelEdit}
        onSave={onTagSave}
        onDelete={onTagDelete}
      />

      {isAdmin && (
        <Tag
          hideIcon={true}
          className={classes.chip}
          label={'+'}
          isSelected={false}
          onClick={openCreateTagDialog}
        />
      )}

      {tags.map(({ id, label }) => {
        return (
          <Tag
            key={id}
            className={classes.chip}
            isSelected={isSelected(id as string)}
            label={label}
            onClick={() => toggleTagSelect(id as string)}
            allowEdit={isAdmin}
            onEdit={() => openEditTagDialog(id as string, label)}
          />
        );
      })}
    </Box>
  );
};
