import { QuotesListsService } from '../../services';
import { AppThunk } from '../store';
import { quotesListsActions } from './quotesListsSlice';

export const initQuotesListsAction = (uid: string): AppThunk => async (
  dispatch,
  getState
) => {
  const state = getState();

  if (state.quotesLists.collection.loaded) return;
  if (state.quotesLists.collection.loading) return;

  dispatch(quotesListsActions.setLoadingState(true));

  await QuotesListsService.ensureDefaultList(uid);

  const lists = await QuotesListsService.getUserLists(uid);

  dispatch(quotesListsActions.acceptData(lists));
  dispatch(
    quotesListsActions.setSelected(
      // we know the default list already exists
      lists.find(list => list.isDefault)?.id as string
    )
  );
};
