import firebase from 'firebase/app';
import chunk from 'lodash/chunk';

import { quotesCollectionRef } from '../firebase/collection-refs';
import {
  firestoreConvertJSDate,
  firestoreTimeStampNow,
  firestoreToPlainObject,
  firestoreToPlainObjectMap
} from '../firebase/utils';
import { IQuote } from '../model';

export class QuotesService {
  private static PAGE_SIZE = 10;

  static async getPublic(timestamp?: string): Promise<IQuote[]> {
    const querySnapshot = await quotesCollectionRef
      .where('isPublic', '==', true)
      .orderBy('timestamp', 'desc')
      .startAfter(firestoreConvertJSDate(timestamp))
      .limit(this.PAGE_SIZE)
      .get();

    return firestoreToPlainObjectMap(querySnapshot.docs);
  }

  static async getQuotesByIdsList(ids: string[]): Promise<IQuote[]> {
    const idsChunks = chunk(ids, 10);

    const quotesChunks = await Promise.all(
      idsChunks.map(ids => this.getQuotesChunk(ids))
    );
    return quotesChunks.flat();
  }

  static async getOtherPeoplePublic(uid: string): Promise<IQuote[]> {
    const querySnapshot = await quotesCollectionRef
      .where('isPublic', '==', true)
      .get();

    return querySnapshot.docs
      .map(d => firestoreToPlainObject(d))
      .filter(d => d.uid !== uid);
  }

  static async create(
    uid: string,
    text: string,
    author: string,
    isPublic: boolean
  ): Promise<IQuote> {
    const documentReference = await quotesCollectionRef.add({
      uid,
      text,
      author,
      isPublic,
      timestamp: firestoreTimeStampNow()
    });

    return this.getById(documentReference.id);
  }

  static async getById(id: string): Promise<IQuote> {
    const documentSnapshot = await quotesCollectionRef.doc(id).get();
    return firestoreToPlainObject(documentSnapshot);
  }

  static async setPublicState(
    uid: string,
    id: string,
    isPublic: boolean
  ): Promise<IQuote> {
    await quotesCollectionRef.doc(id).update({ isPublic });
    return this.getById(id);
  }

  private static async getQuotesChunk(ids: string[]): Promise<IQuote[]> {
    const snapshot = await quotesCollectionRef
      .where(firebase.firestore.FieldPath.documentId(), 'in', ids)
      .get();

    return firestoreToPlainObjectMap(snapshot.docs);
  }
}
