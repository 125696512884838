import { DigestSchedulesService } from '../../services';
import { AppThunk } from '../store';
import { digestActions } from './digestSlice';

export const addDigestScheduleAction = (): AppThunk => async (
  dispatch,
  getState
) => {
  const state = getState();
  const uid = state.auth.user?.uid;

  if (!uid) throw new Error('user is not authenticated');

  const digestSchedule = await DigestSchedulesService.addSchedule(uid, {});

  if (!digestSchedule?.id) {
    throw new Error('Created DigestSchedule id is empty!');
  }

  dispatch(digestActions.acceptSchedules([digestSchedule]));
};
