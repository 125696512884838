import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IQuoteToList } from '../../model';
import {
  acceptCollectionStateData,
  createCollectionState,
  ICollectionState,
  removeFromCollectionState
} from '../utils';

interface QuotesToListsSliceState {
  lastTimestamp: string | null;
  loading: Record<string, boolean>;
  allLoaded: Record<string, boolean>;
  collection: ICollectionState<IQuoteToList>;
}

const initialState: QuotesToListsSliceState = {
  lastTimestamp: null,
  loading: {},
  allLoaded: {},
  collection: createCollectionState<IQuoteToList>()
};

interface SetLoadingPayload {
  list: string;
  loading: boolean;
}

interface SetAllLoadedPayload {
  list: string;
  loaded: boolean;
}

export const quotesToListsSlice = createSlice({
  name: 'quotesToLists',
  initialState,
  reducers: {
    acceptItems(state, action: PayloadAction<IQuoteToList[]>) {
      acceptCollectionStateData(state.collection, action.payload, {
        sortingOrder: 'desc',
        sortingKey: 'quoteTimestamp'
      });
    },

    removeById(state, action: PayloadAction<string>) {
      removeFromCollectionState(state.collection, action.payload);
    },

    setLastTimestamp(state, action: PayloadAction<string>) {
      state.lastTimestamp = action.payload;
    },

    setLoading(state, action: PayloadAction<SetLoadingPayload>) {
      const { list, loading } = action.payload;
      state.loading[list] = loading;
    },

    setAllLoaded(state, action: PayloadAction<SetAllLoadedPayload>) {
      const { list, loaded } = action.payload;
      state.allLoaded[list] = loaded;
    }
  }
});
