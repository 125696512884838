import Box from '@material-ui/core/Box/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import {
  useAuthenticatedUID,
  useGlobalTags,
  useQuoteLocked,
  useQuoteTags
} from '../../redux/selectors';
import { tagQuoteAction, unTagQuoteAction } from '../../redux/tags';
import { Tag } from '../Tag';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'flex-end',
      flex: 'auto',
      marginTop: -theme.spacing(1),
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5)
    },
    tag: {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1)
    }
  })
);

interface QuoteCardTagsProps {
  quoteId: string;
}

export const QuoteCardTags: FC<QuoteCardTagsProps> = ({ quoteId }) => {
  const classes = useStyles();
  const uid = useAuthenticatedUID();
  const dispatch = useDispatch();

  const globalTags = useGlobalTags();
  const quoteTags = useQuoteTags(quoteId);
  const quoteLocked = useQuoteLocked(quoteId);

  const onTagClick = useCallback(
    (tagId: string): void => {
      if (!uid || quoteLocked) return;
      if (quoteTags.includes(tagId)) {
        dispatch(unTagQuoteAction(uid, tagId, quoteId));
      } else {
        dispatch(tagQuoteAction(uid, tagId, quoteId));
      }
    },
    [uid, quoteTags, quoteLocked]
  );

  return (
    <Box className={classes.root}>
      {globalTags.map(tag => {
        return (
          <Tag
            size={'small'}
            className={classes.tag}
            disabled={quoteLocked}
            key={tag.id}
            onClick={() => onTagClick(tag.id as string)}
            isSelected={quoteTags.includes(tag.id as string)}
            label={tag.label}
          />
        );
      })}
    </Box>
  );
};
