import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography/Typography';
import React, { FC } from 'react';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      paddingLeft: 0
    }
  })
);

interface PageTitleProps {
  text: string;
}

export const PageTitle: FC<PageTitleProps> = ({ text }) => {
  const classes = useStyles();
  return (
    <Typography variant="h4" className={classes.root}>
      {text}
    </Typography>
  );
};
