import { IModelBase } from '../../model';
import { ICollectionState } from './ICollectionState';

export const createCollectionState = <
  T extends IModelBase
>(): ICollectionState<T> => {
  return {
    byId: {},
    ids: [],
    loaded: false,
    loading: false,
    loadingIds: []
  };
};
