import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React, { FC, Fragment, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { IDigestSchedule } from '../../model';
import { deleteDigestScheduleAction, digestActions } from '../../redux/digest';
import { useDigestItemEditing } from '../../redux/selectors';
import { DigestScheduleEdit } from './DigestScheduleEdit';
import { DigestScheduleView } from './DigestScheduleView';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(3),
      position: 'relative'
    },

    deleteButton: {
      color: theme.palette.warning.light
    },

    actions: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  })
);

interface DigestScheduleCardProps {
  data: IDigestSchedule;
}

export const DigestScheduleCard: FC<DigestScheduleCardProps> = ({ data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const editing = useDigestItemEditing(data.id);

  const setEditing = useCallback(
    (editing: boolean): void => {
      if (!data.id) return;
      dispatch(digestActions.setEditing({ editing, id: data.id }));
    },
    [data.id]
  );

  const startEdit = useCallback(() => {
    setEditing(true);
  }, [setEditing]);

  const deleteDigest = useCallback(() => {
    if (!data.id) return;
    dispatch(deleteDigestScheduleAction(data.id));
  }, [data.id]);

  return (
    <Card raised={true} className={classes.root}>
      {!editing && <DigestScheduleView data={data} />}
      {editing && <DigestScheduleEdit data={data} />}

      {!editing && (
        <Fragment>
          <Divider />
          <CardActions className={classes.actions}>
            <IconButton
              aria-label="Edit Notification"
              color={'secondary'}
              onClick={startEdit}
            >
              <EditIcon />
            </IconButton>

            <IconButton
              className={classes.deleteButton}
              aria-label="Delete notification"
              onClick={deleteDigest}
            >
              <DeleteIcon />
            </IconButton>
          </CardActions>
        </Fragment>
      )}
    </Card>
  );
};
