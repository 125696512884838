import Box from '@material-ui/core/Box/Box';
import Card from '@material-ui/core/Card/Card';
import CardActions from '@material-ui/core/CardActions/CardActions';
import CardContent from '@material-ui/core/CardContent/CardContent';
import Divider from '@material-ui/core/Divider/Divider';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { IQuote } from '../../model';
import { setQuotePublicFlagAction } from '../../redux/quotes';
import {
  addQuoteToListAction,
  removeQuoteFromListAction
} from '../../redux/quotesLists';
import {
  useAuthenticatedUID,
  useIsQuoteLiked,
  useSelectedListId
} from '../../redux/selectors';
import { QuoteCardTags } from './QuoteCardTags';

interface QuoteCardProps {
  quote: IQuote;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(3)
    },
    quoteText: {},
    quoteAuthorBox: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    quoteAuthor: {
      color: theme.palette.text.secondary
    },
    actions: {
      justifyContent: 'space-between',
      alignItems: 'stretch'
    },
    actionsLeftSection: {
      alignItems: 'flex-start'
    },
    visibilityButton: {
      marginRight: theme.spacing(1)
    },
    deleteButton: {
      color: theme.palette.warning.light
    }
  })
);

const DEBUG_TS = false;

export const QuoteCard: FC<QuoteCardProps> = ({ quote }) => {
  const { author, text, isPublic, id, timestamp } = quote;

  const classes = useStyles();
  const dispatch = useDispatch();

  const uid = useAuthenticatedUID();

  const selectedListId = useSelectedListId();
  const liked = useIsQuoteLiked(id as string);

  const isMine = useMemo<boolean>(() => quote.uid === uid, [uid, quote.uid]);

  const favoriteClickHandler = useCallback(() => {
    if (!selectedListId) throw new Error('selected list id is empty');
    if (!id) throw new Error('Quote id is empty');
    if (!uid) throw new Error('uid is empty');

    if (liked) {
      dispatch(removeQuoteFromListAction(selectedListId, id));
    } else {
      dispatch(addQuoteToListAction(uid, selectedListId, id));
    }
  }, [liked, uid]);

  const publicClickHandler = useCallback(() => {
    if (!id) throw new Error('Quote id is empty');
    if (!uid) throw new Error('uid is empty');
    dispatch(setQuotePublicFlagAction(uid, id, !isPublic));
  }, [isPublic, id]);

  return (
    <Card raised={true} className={classes.root}>
      <CardContent component={'article'}>
        {DEBUG_TS && (
          <Typography variant={'body2'} gutterBottom>
            {timestamp}
          </Typography>
        )}

        {text.split(/\n+/).map((p, i) => (
          <Typography
            key={i}
            variant={'body1'}
            component="p"
            className={classes.quoteText}
          >
            {p}
          </Typography>
        ))}

        <Box className={classes.quoteAuthorBox}>
          <Typography component={'i'} className={classes.quoteAuthor}>
            - {author}
          </Typography>
        </Box>
      </CardContent>

      <Divider />

      <CardActions className={classes.actions}>
        {!isMine && liked !== undefined && (
          <Box className={classes.actionsLeftSection}>
            <IconButton
              aria-label="add to favorites"
              color={'secondary'}
              onClick={favoriteClickHandler}
            >
              {liked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
            </IconButton>
          </Box>
        )}

        {isMine && (
          <Box className={classes.actionsLeftSection}>
            <IconButton
              className={classes.visibilityButton}
              aria-label="toggle quote's public availability"
              color={'secondary'}
              onClick={publicClickHandler}
            >
              {isPublic ? <VisibilityIcon /> : <VisibilityOutlinedIcon />}
            </IconButton>

            <IconButton
              aria-label="Delete your quote forever"
              className={classes.deleteButton}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        )}

        {(isMine || liked) && <QuoteCardTags quoteId={id as string} />}
      </CardActions>
    </Card>
  );
};
