import { useEffect, useState } from 'react';

import { useSelectedTags } from '../../redux/selectors';
import { useQuotesToTagsLoading } from '../../redux/selectors/quotesToTags';

export const useContentLoading = (): boolean => {
  const selectedTagsIds = useSelectedTags();
  const tagsLoading = useQuotesToTagsLoading();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const loadingArray = selectedTagsIds.map(tag => tagsLoading[tag]);
    setLoading(!!loadingArray.length);
  }, [tagsLoading, selectedTagsIds.concat('')]);

  return loading;
};
