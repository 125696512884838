import { IQuoteToTag } from '../../model';
import { QuotesToTagsService } from '../../services';
import { quotesActions } from '../quotes';
import { quotesToTagsSlice } from '../quotesToTags';
import { AppThunk } from '../store';
import { getCollectionStateItem } from '../utils';

export const tagQuoteAction = (
  uid: string,
  tagId: string,
  quoteId: string
): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const quote = getCollectionStateItem(state.quotes.collection, quoteId);

  if (!quote) throw new Error('unexpected empty quote');

  const tempId = `${uid}-${tagId}-${quoteId}`;
  const quoteToTag: IQuoteToTag = {
    timestamp: new Date().toISOString(),
    quoteTimestamp: quote.timestamp,
    uid,
    tag: tagId,
    quote: quoteId,
    id: tempId
  };

  dispatch(quotesActions.lockQuote(quoteId));
  dispatch(
    quotesActions.setQuoteTaggedState({
      tag: tagId,
      quote: quoteId,
      tagged: true
    })
  );
  dispatch(quotesToTagsSlice.actions.acceptItems([quoteToTag]));

  try {
    const quoteToTag = await QuotesToTagsService.tagQuote(
      uid,
      tagId,
      quoteId,
      quote.timestamp as string
    );
    dispatch(quotesToTagsSlice.actions.removeById(tempId));
    dispatch(quotesToTagsSlice.actions.acceptItems([quoteToTag]));
  } catch (error) {
    dispatch(
      quotesActions.setQuoteTaggedState({
        tag: tagId,
        quote: quoteId,
        tagged: false
      })
    );
    dispatch(quotesToTagsSlice.actions.removeById(tempId));
  } finally {
    dispatch(quotesActions.unLockQuote(quoteId));
  }
};
