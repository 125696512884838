import { userSettingsRef } from '../firebase/collection-refs';
import {
  firestoreTimeStampNow,
  firestoreToPlainObject
} from '../firebase/utils';
import { IUserSettings } from '../model';

export class UserSettingsService {
  static async updateUserSettings(
    uid: string,
    update: Partial<Omit<IUserSettings, 'uid' | 'timestamp'>>
  ): Promise<void> {
    const snapshot = await userSettingsRef.doc(uid).get();
    if (snapshot.exists) {
      await snapshot.ref.update(update);
      return;
    }

    await userSettingsRef.doc(uid).set({
      uid,
      timestamp: firestoreTimeStampNow(),
      ...this.formatUpdateData(update)
    });
  }

  static async fetchUserSettings(uid: string): Promise<IUserSettings> {
    const snapshot = await userSettingsRef.doc(uid).get();
    return firestoreToPlainObject(snapshot);
  }

  private static formatUpdateData(
    update: Partial<Omit<IUserSettings, 'uid' | 'timestamp'>>
  ): Partial<Omit<IUserSettings, 'uid' | 'timestamp'>> {
    const clone = { ...update };

    if (clone.phone) clone.phone = this.formatPhone(clone.phone);

    return clone;
  }

  private static formatPhone(phone: string | undefined): string | undefined {
    return phone ? phone.replace(/[\s-()]/g, '') : undefined;
  }
}
