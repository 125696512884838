import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {
  createStyles,
  fade,
  makeStyles,
  Theme
} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import clsx from 'clsx';
import React, {
  FC,
  Fragment,
  MouseEventHandler,
  useCallback,
  useMemo,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import { viewActions } from 'src/redux/view';

import { SIDE_NAV_DRAWER_WIDTH } from '../../appViewConstants';
import { signOutAction } from '../../redux/auth';
import {
  useDrawerViewOpened,
  useIsAuthenticated,
  useIsAuthLoaded,
  useSelectedListId
} from '../../redux/selectors';

const drawerWidth = SIDE_NAV_DRAWER_WIDTH;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      overflow: 'hidden',
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      // width: `0`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    },

    root: {
      position: 'static',
      top: 0,
      display: 'flex',
      justifyContent: 'space-between'
    },

    grow: {
      flexGrow: 1
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block'
      }
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25)
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto'
      }
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    inputRoot: {
      color: 'inherit'
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch'
      }
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex'
      }
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    }
  })
);

export const Header: FC = () => {
  const dispatch = useDispatch();

  const isAuthLoaded = useIsAuthLoaded();
  const isAuthenticated = useIsAuthenticated();
  const selectedListId = useSelectedListId();

  const isDrawerOpened = useDrawerViewOpened();

  const classes = useStyles();

  const [
    mobileMoreAnchorEl,
    setMobileMoreAnchorEl
  ] = useState<null | HTMLElement>(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = useCallback(() => {
    setMobileMoreAnchorEl(null);
  }, [setMobileMoreAnchorEl]);

  const handleMobileMenuOpen = useCallback<MouseEventHandler<HTMLElement>>(
    event => {
      setMobileMoreAnchorEl(event.currentTarget);
    },
    [setMobileMoreAnchorEl]
  );

  const logoutClickHandler = useCallback<MouseEventHandler>(_ => {
    dispatch(signOutAction());
  }, []);

  const openDrawerClickHandler = useCallback<MouseEventHandler>(_ => {
    dispatch(viewActions.setDrawerOpenState(true));
  }, []);

  const mobileMenuId = 'primary-search-account-menu-mobile';

  const renderMobileMenu = useMemo(
    () => (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem>
          <Button
            aria-label="sign out"
            color="inherit"
            onClick={logoutClickHandler}
          >
            Sign Out
          </Button>
        </MenuItem>
      </Menu>
    ),
    [mobileMoreAnchorEl, mobileMenuId, isMobileMenuOpen, handleMobileMenuClose]
  );

  return (
    <div className={clsx(classes.root)}>
      <AppBar
        position="static"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: isDrawerOpened
        })}
      >
        <Toolbar>
          {isAuthenticated && !isDrawerOpened && (
            <IconButton
              onClick={openDrawerClickHandler}
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
            >
              <MenuIcon />
            </IconButton>
          )}

          <Typography className={classes.title} variant="h6" noWrap>
            Selftalk
          </Typography>

          {isAuthenticated && (
            <Fragment>
              {/*<div className={classes.search}>*/}
              {/*  <div className={classes.searchIcon}>*/}
              {/*    <SearchIcon />*/}
              {/*  </div>*/}
              {/*  <InputBase*/}
              {/*    placeholder="Search…"*/}
              {/*    classes={{*/}
              {/*      root: classes.inputRoot,*/}
              {/*      input: classes.inputInput*/}
              {/*    }}*/}
              {/*    inputProps={{ 'aria-label': 'search' }}*/}
              {/*  />*/}
              {/*</div>*/}

              <div className={classes.grow} />

              <div className={classes.sectionDesktop}>
                <Button
                  aria-label="sign out"
                  onClick={logoutClickHandler}
                  color="inherit"
                >
                  Sign Out
                </Button>
              </div>

              <div className={classes.sectionMobile}>
                <IconButton
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </div>
            </Fragment>
          )}
        </Toolbar>

        {(!isAuthLoaded || (isAuthenticated && !selectedListId)) && (
          <LinearProgress color="secondary" />
        )}
      </AppBar>
      {renderMobileMenu}
    </div>
  );
};
