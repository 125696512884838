import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { tz } from 'moment-timezone';
import React, {
  FC,
  FocusEventHandler,
  useCallback,
  useEffect,
  useState
} from 'react';

import { TIMEZONES } from './timezones';

interface TimezoneSelectProps {
  value?: string;
  label?: string;
  onSelect: (value: string) => void;
  onBlur: FocusEventHandler;
}

export const TimezoneSelect: FC<TimezoneSelectProps> = ({
  value,
  label = 'Timezone',
  onSelect,
  onBlur
}) => {
  const [timezone, setTimezone] = useState<string>();

  useEffect(() => setTimezone(value || tz.guess()), [value]);

  useEffect(() => {
    if (!timezone) return;
    onSelect(timezone);
  }, [timezone]);

  const handleChange = useCallback((_, value) => {
    setTimezone(value);
  }, []);

  return (
    <Autocomplete
      id="combo-box-demo"
      options={TIMEZONES}
      onChange={handleChange}
      onBlur={onBlur}
      value={value}
      fullWidth
      renderInput={params => (
        <TextField {...params} label={label} color={'primary'} />
      )}
    />
  );
};
