import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IQuotesList } from '../../model';
import {
  acceptCollectionStateData,
  createCollectionState,
  ICollectionState,
  setLoadingCollectionState
} from '../utils';

interface QuotesListsImportState {
  // ids of lists that are currently have running import process
  importingIn: string[];
}

interface QuotesListsCollectionState {
  collection: ICollectionState<IQuotesList>;
}

interface SelectedList {
  selected: string | null;
}

export type QuotesListsState = QuotesListsCollectionState &
  SelectedList &
  QuotesListsImportState;

const initialState: QuotesListsState = {
  importingIn: [],
  collection: createCollectionState<IQuotesList>(),
  selected: null
};

const quotesListsSlice = createSlice({
  name: 'quotesLists',
  initialState,
  reducers: {
    reset(state) {
      Object.assign(state, initialState);
    },

    startImport(state, { payload }: PayloadAction<string>) {
      if (!state.importingIn.includes(payload)) state.importingIn.push(payload);
    },

    completeImport(state, { payload }: PayloadAction<string>) {
      const index = state.importingIn.indexOf(payload);
      if (index !== -1) state.importingIn.splice(index, 1);
    },

    acceptData(state, { payload }: PayloadAction<IQuotesList[]>) {
      acceptCollectionStateData(state.collection, payload);
    },

    setSelected(state, { payload }: PayloadAction<string | null>) {
      state.selected = payload;
    },

    setLoadingState(state, { payload }: PayloadAction<boolean>) {
      setLoadingCollectionState(state.collection, payload);
    }
  }
});

export const {
  reducer: quotesListsReducer,
  actions: quotesListsActions
} = quotesListsSlice;
