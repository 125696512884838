import { QuotesService } from '../../services';
import { AppThunk } from '../store';
import { quotesActions } from './quotesSlice';
export const setQuotePublicFlagAction = (
  uid: string,
  quoteId: string,
  isPublic: boolean
): AppThunk => async dispatch => {
  await QuotesService.setPublicState(uid, quoteId, isPublic);
  dispatch(
    quotesActions.setPublic({
      isPublic,
      id: quoteId
    })
  );
};
