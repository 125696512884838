import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import LabelIcon from '@material-ui/icons/Label';
import ListIcon from '@material-ui/icons/List';
import PublicIcon from '@material-ui/icons/Public';
import SettingsIcon from '@material-ui/icons/Settings';
import React, { FC, MouseEventHandler, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { viewActions } from 'src/redux/view';

import { APP_ROUTES_CONFIG } from '../../appRoutesConfig';
import { SIDE_NAV_DRAWER_WIDTH } from '../../appViewConstants';
import { useDrawerViewOpened } from '../../redux/selectors';

const drawerWidth = SIDE_NAV_DRAWER_WIDTH;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    activeLink: {
      backgroundColor: theme.palette.secondary.light,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light
      }
    },
    hide: {
      display: 'none'
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end'
    }
  })
);

interface SideNavDrawerProps {}

export const SideNavDrawer: FC<SideNavDrawerProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isDrawerOpen = useDrawerViewOpened();

  const handleDrawerClose = useCallback<MouseEventHandler>(_ => {
    dispatch(viewActions.setDrawerOpenState(false));
  }, []);

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={isDrawerOpen}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>

      <Divider />

      <List>
        <ListItem
          button
          component={NavLink}
          activeClassName={classes.activeLink}
          to={APP_ROUTES_CONFIG.DISCOVER}
        >
          <ListItemIcon>
            <PublicIcon />
          </ListItemIcon>
          <ListItemText primary={'Discover'} />
        </ListItem>

        <ListItem
          button
          component={NavLink}
          activeClassName={classes.activeLink}
          to={APP_ROUTES_CONFIG.LIST}
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary={'My List'} />
        </ListItem>
      </List>

      <Divider />

      <List>
        <ListItem
          button
          component={NavLink}
          activeClassName={classes.activeLink}
          to={APP_ROUTES_CONFIG.TAGS}
        >
          <ListItemIcon>
            <LabelIcon />
          </ListItemIcon>
          <ListItemText primary={'Tags'} />
        </ListItem>
      </List>

      <Divider />

      <List>
        <ListItem
          button
          component={NavLink}
          activeClassName={classes.activeLink}
          to={APP_ROUTES_CONFIG.SETTINGS}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary={'Settings'} />
        </ListItem>
      </List>
    </Drawer>
  );
};
