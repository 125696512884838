import { useSelector } from 'react-redux';

import { RootState } from '../rootReducer';

export const useIsQuotesListImporting = (listId?: string | null): boolean => {
  return useSelector<RootState, boolean>(state => {
    if (!listId) return false;
    return state.quotesLists.importingIn.includes(listId);
  });
};
