import { IModelBase } from '../../model';
import { ICollectionState } from './ICollectionState';

export const updateCollectionStateItem = <T extends IModelBase>(
  collection: ICollectionState<T>,
  id: string,
  data: Partial<T>
): void => {
  const item = collection.byId[id];
  if (!item) throw new Error(`Item ${id} not found`);
  collection.byId[id] = { ...item, ...data };
};
