import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import uniq from 'lodash/uniq';
import without from 'lodash/without';

import { IQuote } from '../../model';
import {
  acceptCollectionStateData,
  createCollectionState,
  ICollectionState
} from '../utils';

interface QuotesCollectionState {
  allLoaded: boolean;
  lastTimestamp: string;
  collection: ICollectionState<IQuote>;
  /** locked state by quoteId */
  locked: Record<string, boolean>;
  /** liked state by quoteId */
  liked: Record<string, boolean>;
  /** tag ids by quoteId */
  tagged: Record<string, string[]>;
}

export type QuotesState = QuotesCollectionState;

const initialState: QuotesState = {
  allLoaded: false,
  lastTimestamp: new Date().toISOString(),
  collection: createCollectionState<IQuote>(),
  locked: {},
  liked: {},
  tagged: {}
};

interface SetPublicStatePayload {
  id: string;
  isPublic: boolean;
}

interface SetQuoteLikedPayload {
  quote: string;
  liked: boolean;
}

type SetQuoteLikedMultiplePayload = Record<string, boolean>;

interface SetQuoteTaggedPayload {
  quote: string;
  tag: string;
  tagged: boolean;
}

type SetQuoteTaggedMultiplePayload = Record<string, string[]>;

const quotesSlice = createSlice({
  name: 'quotes',
  initialState,
  reducers: {
    reset(state) {
      Object.assign(state, initialState);
    },

    resetQuotesCollection(state) {
      Object.assign(state.collection, initialState.collection);
    },

    setLastTimestamp(state, action: PayloadAction<string>) {
      state.lastTimestamp = action.payload;
    },

    setAllLoaded(state, action: PayloadAction<boolean>) {
      state.allLoaded = action.payload;
    },
    acceptData(state, { payload }: PayloadAction<IQuote[]>) {
      acceptCollectionStateData(state.collection, payload, {
        sortingKey: 'timestamp',
        sortingOrder: 'desc'
      });
    },
    setPublic(state, { payload }: PayloadAction<SetPublicStatePayload>) {
      state.collection.byId[payload.id].isPublic = payload.isPublic;
    },

    setQuoteLikedState(state, action: PayloadAction<SetQuoteLikedPayload>) {
      const { liked, quote } = action.payload;
      state.liked[quote] = liked;
    },
    setQuoteLikedStateMultiple(
      state,
      action: PayloadAction<SetQuoteLikedMultiplePayload>
    ) {
      Object.assign(state.liked, action.payload);
    },

    setQuoteTaggedState(state, action: PayloadAction<SetQuoteTaggedPayload>) {
      const { tag, quote, tagged } = action.payload;
      const prevState = state.tagged[quote] || [];

      if (tagged) {
        state.tagged[quote] = uniq(prevState.concat(tag));
      } else {
        state.tagged[quote] = without(prevState, tag);
      }
    },
    setQuoteTaggedStateMultiple(
      state,
      action: PayloadAction<SetQuoteTaggedMultiplePayload>
    ) {
      Object.assign(state.tagged, action.payload);
    },

    lockQuote(state, action: PayloadAction<string>) {
      state.locked[action.payload] = true;
    },
    unLockQuote(state, action: PayloadAction<string>) {
      state.locked[action.payload] = false;
    }
  }
});

export const { reducer: quotesReducer, actions: quotesActions } = quotesSlice;
