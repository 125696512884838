import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { createQuoteInCurrentListAction } from '../../redux/commonActions';
import { useNewQuoteViewOpened } from '../../redux/selectors';
import { viewActions } from '../../redux/view';
import { Transition } from '../Animations';
import { QuoteForm, QuoteFormData } from '../QuoteForm';

interface AddQuoteDialogProps {}

const useStyles = makeStyles(theme =>
  createStyles({
    dialog: {
      marginBottom: theme.spacing(5)
    }
  })
);

export const AddQuoteDialog: FC<AddQuoteDialogProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isOpen = useNewQuoteViewOpened();

  const handleClose = useCallback(() => {
    dispatch(viewActions.setNewQuoteOpenState(false));
  }, []);

  const onSubmit = useCallback((data: QuoteFormData) => {
    const { text, author, isPublic } = data;
    dispatch(viewActions.setNewQuoteOpenState(false));
    dispatch(createQuoteInCurrentListAction(text, author, isPublic));
  }, []);

  return (
    <Dialog
      maxWidth={'sm'}
      fullWidth
      className={classes.dialog}
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <DialogContent>
        <QuoteForm onSubmit={onSubmit} />
      </DialogContent>
    </Dialog>
  );
};
