import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ITag } from '../../model';
import {
  acceptCollectionStateData,
  createCollectionState,
  ICollectionState,
  removeFromCollectionState,
  updateCollectionStateItem
} from '../utils';

interface GlobalTagsState {
  globalTagsCollection: ICollectionState<ITag>;
}

interface SelectedTags {
  selectedTags: string[];
}

interface LockedLists {
  lockedLists: Record<string, boolean>;
}

export type TagsState = GlobalTagsState & SelectedTags & LockedLists;

const initialState: TagsState = {
  globalTagsCollection: createCollectionState<ITag>(),
  selectedTags: [],
  lockedLists: {}
};

interface UpdateGlobalTagPayload {
  id: string;
  data: { label: string };
}

interface ByIDPayload {
  id: string;
}

const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    reset(state) {
      Object.assign(state, initialState);
    },

    updateGlobalTag(state, action: PayloadAction<UpdateGlobalTagPayload>) {
      const { data, id } = action.payload;
      updateCollectionStateItem(state.globalTagsCollection, id, data);
    },

    acceptGlobalTags(state, action: PayloadAction<ITag[]>) {
      acceptCollectionStateData(state.globalTagsCollection, action.payload);
    },

    removeGlobalTag(state, action: PayloadAction<ByIDPayload>) {
      removeFromCollectionState(state.globalTagsCollection, action.payload.id);
    },

    selectTag(state, { payload }: PayloadAction<string>) {
      if (state.selectedTags.includes(payload)) return;
      state.selectedTags.push(payload);
    },

    unSelectTag(state, { payload }: PayloadAction<string>) {
      const index = state.selectedTags.indexOf(payload);
      if (index !== -1) state.selectedTags.splice(index, 1);
    },

    lockTagList(state, action: PayloadAction<string>) {
      state.lockedLists[action.payload] = true;
    },
    unLockTagList(state, action: PayloadAction<string>) {
      state.lockedLists[action.payload] = false;
    }
  }
});

export const { actions: tagsActions, reducer: tagsReducer } = tagsSlice;
