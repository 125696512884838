import difference from 'lodash/difference';

import { QuotesToTagsService } from '../../services';
import { quotesActions } from '../quotes';
import { AppThunk } from '../store';

export const checkTaggedStateAction = (quoteIds: string[]): AppThunk => async (
  dispatch,
  getState
) => {
  const state = getState();
  const uid = state.auth.user?.uid;

  if (!uid) return;

  const loaded = Object.keys(state.quotes.tagged);
  const notLoaded = difference(quoteIds, loaded);

  const taggedState = await QuotesToTagsService.checkTaggedState(
    uid,
    notLoaded
  );

  dispatch(quotesActions.setQuoteTaggedStateMultiple(taggedState));
};
