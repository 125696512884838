import Box from '@material-ui/core/Box/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FC, useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { AppRoutes } from './AppRoutes';
import { SIDE_NAV_DRAWER_WIDTH } from './appViewConstants';
import { AddNoteDialog } from './components/AddNoteDialog';
import { AddQuoteDialog } from './components/AddQuoteDialog';
import { AuthStateListener } from './components/AuthStateListener';
import { FloatingAddNoteButton } from './components/FloatingAddNoteButton';
import { FloatingAddQuoteButton } from './components/FloatingAddQuoteButton';
import { Header } from './components/Header';
import { InfiniteScrollContextValue } from './components/InfiniteScroll';
import { InfiniteScrollProvider } from './components/InfiniteScroll/InfiniteScrollProvider';
import { useInfiniteScroll } from './components/InfiniteScroll/useInfiniteScroll';
import { SideNavDrawer } from './components/SideNavDrawer';
import { useDrawerViewOpened, useIsAuthenticated } from './redux/selectors';

const drawerWidth = SIDE_NAV_DRAWER_WIDTH;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100vh',
      width: '100vw',
      overflowY: 'hidden',
      display: 'flex',
      flexDirection: 'column'
    },
    content: {
      flex: 'auto',
      overflowY: 'scroll',
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginLeft: 0
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: drawerWidth
    }
  })
);

interface AppProps {}

export const App: FC<AppProps> = () => {
  const classes = useStyles();
  const isDrawerOpen = useDrawerViewOpened();
  const isAuthenticated = useIsAuthenticated();

  const {
    page,
    loaderRef,
    scrollContainerRef,
    setHasMore,
    hasMore,
    reset
  } = useInfiniteScroll();

  const infiniteScrollContextValue = useMemo<InfiniteScrollContextValue>(
    () => ({
      page,
      hasMore,
      setHasMore,
      reset
    }),
    [page, hasMore, setHasMore, reset]
  );

  return (
    <BrowserRouter>
      <InfiniteScrollProvider value={infiniteScrollContextValue}>
        <Box className={classes.container}>
          <AuthStateListener />
          <Header />
          <SideNavDrawer />
          <main
            ref={scrollContainerRef}
            className={clsx(classes.content, {
              [classes.contentShift]: isDrawerOpen
            })}
          >
            <AppRoutes />
            <span ref={loaderRef} />
          </main>

          {isAuthenticated && <AddQuoteDialog />}
          {isAuthenticated && <AddNoteDialog />}

          {isAuthenticated && <FloatingAddNoteButton />}
          {isAuthenticated && <FloatingAddQuoteButton />}
        </Box>
      </InfiniteScrollProvider>
    </BrowserRouter>
  );
};
