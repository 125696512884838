import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Hidable {
  isOpen: boolean;
}

interface NewQuoteViewState {
  newQuote: Hidable;
}

interface NewNoteViewState {
  newNote: Hidable;
}

interface DrawerViewState {
  drawer: {
    isOpen: boolean;
  };
}

export type ViewState = NewQuoteViewState & DrawerViewState & NewNoteViewState;

const initialState: ViewState = {
  newQuote: { isOpen: false },
  newNote: { isOpen: false },
  drawer: { isOpen: false }
};

const viewSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    reset(state) {
      Object.assign(state, initialState);
    },

    setNewQuoteOpenState(state, action: PayloadAction<boolean>) {
      state.newQuote.isOpen = action.payload;
    },

    setNewNoteOpenState(state, action: PayloadAction<boolean>) {
      state.newNote.isOpen = action.payload;
    },

    setDrawerOpenState(state, action: PayloadAction<boolean>) {
      state.drawer.isOpen = action.payload;
    }
  }
});

export const { reducer: viewReducer, actions: viewActions } = viewSlice;
