import { combineReducers } from '@reduxjs/toolkit';

import { authReducer } from './auth';
import { digestReducer } from './digest';
import { quotesReducer } from './quotes';
import { quotesListsReducer } from './quotesLists';
import { quotesToListsSlice } from './quotesToLists';
import { quotesToTagsSlice } from './quotesToTags';
import { tagsReducer } from './tags';
import { userSettingsSlice } from './userSettings';
import { viewReducer } from './view';

const rootReducer = combineReducers({
  auth: authReducer,
  quotes: quotesReducer,
  quotesLists: quotesListsReducer,
  view: viewReducer,
  tags: tagsReducer,
  digest: digestReducer,
  quotesToLists: quotesToListsSlice.reducer,
  quotesToTags: quotesToTagsSlice.reducer,
  userSettings: userSettingsSlice.reducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
