import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IUser } from '../../model';

interface AuthenticatedState {
  isAuthenticated: boolean;
  user: IUser | null;
}

interface AuthLoadedState {
  isLoaded: boolean;
}

interface RoleState {
  isAdmin: boolean;
}

export type AuthState = AuthenticatedState & AuthLoadedState & RoleState;

//  ------------------------------------

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  isLoaded: false,
  isAdmin: false
};

//  ------------------------------------

interface SetAuthenticatedPayload {
  isAuthenticated: boolean;
  user: IUser | null;
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset(state) {
      Object.assign(state, initialState);
    },

    setLoadedState(state, action: PayloadAction<boolean>) {
      state.isLoaded = action.payload;
    },

    setAuthenticated(
      state,
      { payload }: PayloadAction<SetAuthenticatedPayload>
    ) {
      state.isAuthenticated = payload.isAuthenticated;
      state.user = payload.user;
      state.isLoaded = true;
    },

    setIsAdmin(state, action: PayloadAction<boolean>) {
      state.isAdmin = action.payload;
    }
  }
});

export const { reducer: authReducer, actions: authActions } = authSlice;
