import firebase from 'firebase/app';

import { IModelBase } from '../../model';
import { firestoreToPlainObject } from './firestoreToPlainObject';

export const firestoreToPlainObjectMap = <T extends IModelBase>(
  documentSnapshots: firebase.firestore.DocumentSnapshot<T>[]
): T[] => {
  return documentSnapshots.map(ds => firestoreToPlainObject(ds));
};
