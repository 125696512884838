import last from 'lodash/last';

import { QuotesService } from '../../services';
import { AppThunk } from '../store';
import { quotesActions } from './quotesSlice';

export const loadAllPublicQuotesAction = (): AppThunk => async (
  dispatch,
  getState
) => {
  const state = getState();
  const quotes = await QuotesService.getPublic(state.quotes.lastTimestamp);

  if (quotes.length === 0) {
    dispatch(quotesActions.setAllLoaded(true));
  } else {
    const newTimestamp = last(quotes)?.timestamp as string;

    dispatch(quotesActions.setLastTimestamp(newTimestamp));
    dispatch(quotesActions.acceptData(quotes));
  }
};
