import { QuotesToTagsService } from '../../services';
import { quotesActions } from '../quotes';
import { quotesToTagsSlice } from '../quotesToTags';
import { AppThunk } from '../store';
import { getCollectionItems } from '../utils';

export const unTagQuoteAction = (
  uid: string,
  tagId: string,
  quoteId: string
): AppThunk => async (dispatch, getState) => {
  const state = getState();

  const quoteToTags = getCollectionItems(state.quotesToTags.collection);

  // This item might be not loaded yet
  const itemToDelete = quoteToTags.find(
    q => q.quote === quoteId && q.tag === tagId && q.uid === uid
  );

  dispatch(quotesActions.lockQuote(quoteId));
  dispatch(
    quotesActions.setQuoteTaggedState({
      tag: tagId,
      quote: quoteId,
      tagged: false
    })
  );

  try {
    if (itemToDelete?.id) {
      dispatch(quotesToTagsSlice.actions.removeById(itemToDelete.id));
      await QuotesToTagsService.removeById(itemToDelete.id);
    } else {
      await QuotesToTagsService.unTagQuote(uid, tagId, quoteId);
    }
  } catch (error) {
    if (itemToDelete) {
      dispatch(quotesToTagsSlice.actions.acceptItems([itemToDelete]));
    }
    dispatch(
      quotesActions.setQuoteTaggedState({
        tag: tagId,
        quote: quoteId,
        tagged: true
      })
    );
  } finally {
    dispatch(quotesActions.unLockQuote(quoteId));
  }
};
