import { TagsService } from '../../services';
import { AppThunk } from '../store';
import { tagsActions } from './tagsSlice';

export const deleteGlobalTagAction = (
  id: string
): AppThunk => async dispatch => {
  await TagsService.removeGlobalTag(id);
  dispatch(tagsActions.removeGlobalTag({ id }));
};
