import { useSelector } from 'react-redux';

import { RootState } from '../rootReducer';
import { useIsAuthenticated } from './useIsAuthenticated';

export const useAuthenticatedUID = (): string | null => {
  const isAuthenticated = useIsAuthenticated();
  if (!isAuthenticated) return null;
  return useSelector<RootState, string>(
    state => state.auth.user?.uid as string
  );
};
