import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, Fragment, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  addDigestScheduleAction,
  loadDigestSchedulesAction
} from '../../redux/digest';
import { useDigestItems } from '../../redux/selectors';
import { DigestScheduleCard } from './DigestScheduleCard';
import { SettingsSectionTitle } from './SettingsSectionTitle';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addButton: {
      marginBottom: theme.spacing(3)
    }
  })
);
interface DigestSettingsProps {}

export const DigestSchedule: FC<DigestSettingsProps> = () => {
  const dispatch = useDispatch();
  const digestItems = useDigestItems();
  const classes = useStyles();

  const handleClick = useCallback(() => {
    dispatch(addDigestScheduleAction());
  }, []);

  useEffect(() => {
    dispatch(loadDigestSchedulesAction());
  }, []);

  return (
    <Fragment>
      <SettingsSectionTitle text={'Notifications Settings'} />

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        size={'large'}
        onClick={handleClick}
        className={classes.addButton}
      >
        Add New Notification
      </Button>

      {digestItems.map(item => (
        <DigestScheduleCard key={item.id} data={item} />
      ))}
    </Fragment>
  );
};
