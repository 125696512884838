import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField/TextField';
import React, { FC, useCallback, useEffect, useState } from 'react';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      minWidth: '450px'
    },
    actions: {
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between'
    },
    button: {
      marginLeft: theme.spacing(1),
      '&:first-of-type': {
        marginLeft: 0
      }
    }
  })
);

interface EditTagModalProps {
  label: string;
  open: boolean;
  createMode?: boolean;
  onCancel: () => void;
  onSave: (newValue: string) => void;
  onDelete: () => void;
}

export const TagEditDialog: FC<EditTagModalProps> = ({
  label,
  open,
  createMode = false,
  onCancel,
  onSave,
  onDelete
}) => {
  const [localLabel, setLocalLabel] = useState(label);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const classes = useStyles();

  const updateLabel = useCallback(ev => {
    setLocalLabel(ev.target.value);
  }, []);

  const handleSave = useCallback(() => {
    onSave(localLabel);
  }, [localLabel, onSave]);

  const handleDelete = useCallback(() => {
    setShowDeleteConfirm(true);
  }, []);

  const handleDeleteCancel = useCallback(() => {
    setShowDeleteConfirm(false);
  }, []);

  const handleDeleteConfirm = useCallback(() => {
    onDelete();
  }, [onDelete]);

  useEffect(() => {
    setLocalLabel(label);
    setShowDeleteConfirm(false);
  }, [label]);

  return (
    <Dialog open={open} onClose={onCancel}>
      <Box className={classes.root}>
        <DialogTitle>{createMode ? 'Create' : 'Edit'} Tag</DialogTitle>

        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="tag"
            label="Tag Label"
            type="tag"
            value={localLabel}
            onChange={updateLabel}
            fullWidth
          />
        </DialogContent>

        <DialogActions className={classes.actions}>
          <Box>
            {!createMode && !showDeleteConfirm && (
              <Button
                size={'small'}
                className={classes.button}
                variant={'contained'}
                onClick={handleDelete}
                color="default"
              >
                Delete
              </Button>
            )}

            {showDeleteConfirm && (
              <Button
                size={'small'}
                className={classes.button}
                variant={'contained'}
                onClick={handleDeleteCancel}
                color="default"
              >
                No
              </Button>
            )}

            {showDeleteConfirm && (
              <Button
                size={'small'}
                className={classes.button}
                variant={'contained'}
                onClick={handleDeleteConfirm}
                color="default"
              >
                Yes, Delete
              </Button>
            )}
          </Box>

          {/*---*/}

          <Box>
            <Button
              size={'small'}
              className={classes.button}
              onClick={onCancel}
              color="primary"
            >
              Cancel
            </Button>

            <Button
              size={'small'}
              className={classes.button}
              variant={'contained'}
              onClick={handleSave}
              color="secondary"
            >
              Save
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
