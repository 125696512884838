import { tz } from 'moment-timezone';

import { UserSettingsService } from '../../services';
import { AppThunk } from '../store';
import { saveUserSettingsAction } from './saveUserSettingsAction';
import { userSettingsSlice } from './userSettingsSlice';

export const loadUserSettingsAction = (): AppThunk => async (
  dispatch,
  getState
) => {
  const state = getState();

  const uid = state.auth.user?.uid;

  // eslint-disable-next-line no-console
  console.log(uid);
  if (!uid) return;

  try {
    const settings = await UserSettingsService.fetchUserSettings(uid);

    if (!settings.timezone) {
      dispatch(saveUserSettingsAction({ timezone: tz.guess() }));
    }

    dispatch(userSettingsSlice.actions.setSettings(settings));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(error);
  }
};
