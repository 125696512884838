import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { FC } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.grey['600'],
      margin: theme.spacing(3, 0, 2)
    }
  })
);

interface SettingsSectionTitleProps {
  text: string;
}

export const SettingsSectionTitle: FC<SettingsSectionTitleProps> = ({
  text
}) => {
  const classes = useStyles();
  return (
    <Typography variant={'h5'} className={classes.root}>
      {text}
    </Typography>
  );
};
