import firebase from 'firebase/app';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { APP_ROUTES_CONFIG } from '../../appRoutesConfig';
import { getFirebase } from '../../firebase';
import { authActions, loadUserRoleAction } from '../../redux/auth';
import { resetStoreAction } from '../../redux/commonActions';
import { initQuotesListsAction } from '../../redux/quotesLists';
import { initTagsAction } from '../../redux/tags';
import { EmailRecipientsService } from '../../services';

type UserInfo = firebase.UserInfo;

interface AuthStateListenerProps {}

export const AuthStateListener: FC<AuthStateListenerProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    getFirebase()
      .auth()
      .onAuthStateChanged(user => {
        // eslint-disable-next-line no-console
        console.log('auth changed', user?.uid);
        if (user && user.uid && user.email) {
          dispatch(
            authActions.setAuthenticated({
              isAuthenticated: true,
              user: user.toJSON() as UserInfo
            })
          );

          dispatch(loadUserRoleAction(user));
          dispatch(initQuotesListsAction(user.uid));
          dispatch(initTagsAction());

          // TODO: move it to cloud functions
          // noinspection JSIgnoredPromiseFromCall
          EmailRecipientsService.ensureRecipientForAUser(user.uid, user.email);

          if (window.location.pathname.startsWith(APP_ROUTES_CONFIG.AUTH)) {
            history.push(APP_ROUTES_CONFIG.DISCOVER);
          }
        } else {
          dispatch(resetStoreAction());
          dispatch(authActions.setLoadedState(true));
          history.push('/');
        }
      });
  }, []);

  return null;
};
