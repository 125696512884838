import { TagsService } from '../../services';
import { AppThunk } from '../store';
import { tagsActions } from './tagsSlice';

export const updateTagLabelAction = (
  id: string,
  label: string
): AppThunk => async dispatch => {
  const data = { label };
  await TagsService.updateTag(id, data);
  dispatch(tagsActions.updateGlobalTag({ id, data }));
};
