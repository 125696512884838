import Box from '@material-ui/core/Box/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC, useCallback } from 'react';

import { Tag } from '../../components/Tag';
import { useGlobalTags } from '../../redux/selectors';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {},
    chip: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  })
);

interface DigestScheduleEditTagsProps {
  onSelect: (id: string) => void;
  onDeselect: (id: string) => void;
  selectedIds: string[];
}

export const DigestScheduleEditTags: FC<DigestScheduleEditTagsProps> = ({
  onSelect,
  onDeselect,
  selectedIds
}) => {
  const classes = useStyles();

  const tags = useGlobalTags();

  const toggleTagSelect = useCallback(
    (id: string): void => {
      if (selectedIds.includes(id)) {
        onDeselect(id);
      } else {
        onSelect(id);
      }
    },
    [selectedIds]
  );

  const isSelected = (id: string): boolean => selectedIds.includes(id);

  return (
    <Box className={classes.root}>
      {tags.map(({ id, label }) => {
        return (
          <Tag
            key={id}
            className={classes.chip}
            isSelected={isSelected(id as string)}
            label={label}
            onClick={() => toggleTagSelect(id as string)}
          />
        );
      })}
    </Box>
  );
};
