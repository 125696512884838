import { FormControlLabel } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import React, { FC, Fragment, useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { IDigestSchedule } from '../../model';
import { digestActions, updateDigestScheduleAction } from '../../redux/digest';
import { useUserSettings } from '../../redux/userSettings/selectors/useUserSettings';
import { DigestScheduleEditTags } from './DigestScheduleEditTags';

const toHours = (minutes: number): number => {
  return minutes / 60;
};

const format = (number: number): string => `${toHours(number)}:00`;

interface ValueLabelProps {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

const ValueLabelComponent: FC<ValueLabelProps> = ({
  children,
  open,
  value
}) => {
  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editModeActions: {
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center'
    },

    deleteButton: {
      color: theme.palette.warning.light
    },

    sliderHolder: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  })
);

interface DigestScheduleEditProps {
  data: IDigestSchedule;
}

const defaultDeliverBy: IDigestSchedule['deliverBy'] = {
  email: true,
  sms: false
};
export const DigestScheduleEdit: FC<DigestScheduleEditProps> = ({ data }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { phone } = useUserSettings();

  const [tags, setTags] = useState([...data.tags]);

  const { handleSubmit, control, watch } = useForm<IDigestSchedule>({
    defaultValues: {
      ...data,
      deliverBy: {
        ...defaultDeliverBy,
        ...(data.deliverBy || {})
      }
    }
  });

  const submitHandler = handleSubmit(changes => {
    if (!data.id) return;

    dispatch(
      digestActions.setEditing({ id: data.id as string, editing: false })
    );

    dispatch(
      updateDigestScheduleAction(data.id as string, {
        ...changes,
        tags
      })
    );
  });

  const cancelHandler = useCallback(() => {
    dispatch(
      digestActions.setEditing({ id: data.id as string, editing: false })
    );
  }, []);

  const addTag = useCallback(
    id => {
      if (!tags.includes(id)) setTags(tags.concat(id));
    },
    [tags]
  );

  const rmTag = useCallback(
    id => {
      if (tags.includes(id)) setTags(tags.filter(item => item !== id));
    },
    [tags]
  );

  return (
    <Fragment>
      <form onSubmit={submitHandler}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography gutterBottom variant={'subtitle1'}>
                Send quotes with tags
              </Typography>

              <DigestScheduleEditTags
                onSelect={addTag}
                onDeselect={rmTag}
                selectedIds={tags}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography gutterBottom variant={'subtitle1'}>
                Every day at {format(watch('time'))}
              </Typography>

              <Box className={classes.sliderHolder}>
                <Controller
                  name={'time'}
                  control={control}
                  render={props => (
                    <Slider
                      {...props}
                      onChange={(_, value) => {
                        props.onChange(value);
                      }}
                      defaultValue={6 * 60}
                      aria-labelledby="time-slider"
                      valueLabelDisplay="auto"
                      step={60}
                      marks
                      min={0}
                      max={23 * 60}
                      valueLabelFormat={format}
                      ValueLabelComponent={ValueLabelComponent}
                    />
                  )}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Typography gutterBottom variant={'subtitle1'}>
                By
              </Typography>

              <Controller
                name={'deliverBy[email]'}
                control={control}
                render={props => (
                  <FormControlLabel
                    label={'Email'}
                    style={{ userSelect: 'none' }}
                    control={
                      <Checkbox
                        onChange={(_, value) => props.onChange(value)}
                        checked={props.value}
                        inputProps={{ 'aria-label': 'Deliver by email' }}
                      />
                    }
                  />
                )}
              />

              <Controller
                name={'deliverBy[sms]'}
                control={control}
                render={props => (
                  <FormControlLabel
                    label={'SMS'}
                    disabled={!phone}
                    style={{ userSelect: 'none' }}
                    control={
                      <Checkbox
                        onChange={(_, value) => props.onChange(value)}
                        checked={!!phone && props.value}
                        inputProps={{ 'aria-label': 'Deliver by SMS' }}
                      />
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>

        <Divider />

        <CardActions className={classes.editModeActions}>
          <Button onClick={cancelHandler}>Cancel</Button>
          <Button type={'submit'} disabled={!tags.length}>
            Save
          </Button>
        </CardActions>
      </form>
    </Fragment>
  );
};
