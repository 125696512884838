import { getFirebase } from '../../firebase';
import { AppThunk } from '../store';

export const signInWithEmailAndPasswordAction = (
  email: string,
  password: string
): AppThunk => async _ => {
  try {
    await getFirebase().auth().signInWithEmailAndPassword(email, password);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
};
