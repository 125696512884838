import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import pick from 'lodash/pick';

import { IUserSettings } from '../../model';

export interface UserSettingsSliceState {
  loading: boolean;
  phone?: string;
  firstName?: string;
  lastName?: string;
  timezone?: string;
}

const initialState: UserSettingsSliceState = {
  loading: false,
  phone: undefined,
  firstName: undefined,
  lastName: undefined,
  timezone: undefined
};

export type SetSettingsPayload = Pick<
  IUserSettings,
  'firstName' | 'lastName' | 'phone' | 'timezone'
>;

export const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {
    reset(state) {
      Object.assign(state, initialState);
    },

    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    setSettings(state, action: PayloadAction<SetSettingsPayload>) {
      Object.assign(
        state,
        pick(action.payload, ['phone', 'firstName', 'lastName'])
      );
    }
  }
});
