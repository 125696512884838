import { orderBy } from 'lodash';
import { useSelector } from 'react-redux';

import { IQuote } from '../../model';
import { RootState } from '../rootReducer';
import { getCollectionItems, getCollectionStateItem } from '../utils';
import { useSelectedList } from './useSelectedList';

export const useSelectedListQuotes = (): IQuote[] => {
  const selectedList = useSelectedList();

  return useSelector<RootState, IQuote[]>(state => {
    if (!selectedList) return [];

    const items = getCollectionItems(state.quotesToLists.collection);

    const relevantItems = items.filter(i => i.list === selectedList.id);
    const sortedItems = orderBy(relevantItems, ['quoteTimestamp'], 'desc');

    return sortedItems
      .map(item => getCollectionStateItem(state.quotes.collection, item.quote))
      .filter(Boolean) as IQuote[];
  });
};
