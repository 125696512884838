import { DigestSchedulesService } from '../../services';
import { AppThunk } from '../store';
import { digestActions } from './digestSlice';

export const loadDigestSchedulesAction = (): AppThunk => async (
  dispatch,
  getState
) => {
  const state = getState();
  const uid = state.auth.user?.uid;

  if (!uid) throw new Error('user is not authenticated');

  const digestSchedules = await DigestSchedulesService.getUserSchedules(uid);

  dispatch(digestActions.acceptSchedules(digestSchedules));
};
