import { useSelector } from 'react-redux';

import { IDigestSchedule } from '../../model';
import { RootState } from '../rootReducer';
import { getCollectionItems } from '../utils';

export const useDigestItems = (): IDigestSchedule[] => {
  return useSelector<RootState, IDigestSchedule[]>(state => {
    return getCollectionItems(state.digest.collection);
  });
};
