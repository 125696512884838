import { InputAdornment, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { tz } from 'moment-timezone';
import React, { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { TimezoneSelect } from '../../components/TimezoneSelect';
import {
  loadUserSettingsAction,
  saveUserSettingsAction
} from '../../redux/userSettings';
import { useUserSettings } from '../../redux/userSettings/selectors/useUserSettings';
import { SettingsSectionTitle } from './SettingsSectionTitle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    submitButton: {
      margin: theme.spacing(2, 0, 3)
    },
    field: {
      margin: theme.spacing(0, 0, 2)
    }
  })
);

interface UserSettingsProps {}

interface UserSettingsFormState {
  phone: string;
  firstName: string;
  lastName: string;
  timezone: string;
}

export const UserSettings: FC<UserSettingsProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    loading,
    phone = '',
    firstName,
    lastName,
    timezone = tz.guess()
  } = useUserSettings();

  const {
    register,
    handleSubmit,
    formState,
    reset,
    control,
    trigger
  } = useForm<UserSettingsFormState>({
    mode: 'all',
    defaultValues: { phone, firstName, lastName, timezone }
  });

  const onSubmit = handleSubmit(({ phone, firstName, lastName, timezone }) => {
    dispatch(saveUserSettingsAction({ phone, firstName, lastName, timezone }));
  });

  useEffect(() => {
    reset({ phone, firstName, lastName, timezone });
    setTimeout(trigger, 1000);
  }, [phone, firstName, lastName, timezone]);

  useEffect(() => {
    dispatch(loadUserSettingsAction());
  }, []);

  return (
    <>
      <SettingsSectionTitle text={'User Settings'} />

      <form onSubmit={onSubmit}>
        <TextField
          fullWidth
          className={classes.field}
          InputLabelProps={{ shrink: true }}
          name={'firstName'}
          inputRef={register()}
          variant={'standard'}
          label={'First Name'}
        />
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          name={'lastName'}
          className={classes.field}
          inputRef={register()}
          variant={'standard'}
          label={'Last Name'}
        />

        <TextField
          fullWidth
          name={'phone'}
          className={classes.field}
          InputProps={{
            startAdornment: (
              <InputAdornment position={'start'}>+ </InputAdornment>
            )
          }}
          inputRef={register({ pattern: /^[\d-\s]+$/, required: false })}
          placeholder={'123456'}
          error={!!formState.errors.phone}
          variant={'standard'}
          label={'Mobile Phone'}
          type={'phone'}
        />

        <Controller
          name={'timezone'}
          control={control}
          render={({ onChange, onBlur, value }) => (
            <TimezoneSelect
              label={'Timezone'}
              onBlur={onBlur}
              onSelect={v => onChange(v)}
              value={value}
            />
          )}
        />

        <Button
          disabled={loading || !formState.isDirty || !formState.isValid}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          size={'large'}
          className={classes.submitButton}
        >
          {loading && 'saving...'}
          {!loading && 'Save'}
        </Button>
      </form>
    </>
  );
};
