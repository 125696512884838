import last from 'lodash/last';

import { QuotesService, QuotesToListsService } from '../../services';
import { quotesActions } from '../quotes';
import { quotesToListsSlice } from '../quotesToLists';
import { AppThunk } from '../store';

export const loadMoreListQuotesAction = (list: string): AppThunk => async (
  dispatch,
  getState
) => {
  const state = getState();
  const { lastTimestamp, allLoaded } = state.quotesToLists;

  if (!allLoaded) return;

  dispatch(quotesToListsSlice.actions.setLoading({ list, loading: true }));

  const quoteToLists = await QuotesToListsService.find(
    { list },
    { lastTimestamp }
  );

  if (quoteToLists.length === 0) {
    dispatch(quotesToListsSlice.actions.setLoading({ list, loading: false }));
    dispatch(quotesToListsSlice.actions.setAllLoaded({ list, loaded: true }));
    return;
  }

  const quoteIds = quoteToLists.map(item => item.quote);
  const quotes = await QuotesService.getQuotesByIdsList(quoteIds);
  const newLastTimestamp = last(quoteToLists)?.quoteTimestamp as string;

  dispatch(quotesActions.acceptData(quotes));

  dispatch(quotesToListsSlice.actions.setLastTimestamp(newLastTimestamp));
  dispatch(quotesToListsSlice.actions.acceptItems(quoteToLists));

  dispatch(quotesToListsSlice.actions.setLoading({ list, loading: false }));
};
