import Box from '@material-ui/core/Box/Box';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Container from '@material-ui/core/Container/Container';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography/Typography';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useInfiniteScrollContext } from '../../components/InfiniteScroll';
import { PageTitle } from '../../components/PageTitle';
import { QuotesList } from '../../components/QuotesList';
import { loadAllPublicQuotesAction, quotesActions } from '../../redux/quotes';
import {
  useAllPublicQuotes,
  useAllQuotesLoaded,
  useAuthenticatedUID
} from '../../redux/selectors';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {},
    listEnd: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    loaderHolder: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  })
);

interface DiscoverPageProps {}

export const DiscoverPage: FC<DiscoverPageProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const quotes = useAllPublicQuotes();
  const allQuotesLoaded = useAllQuotesLoaded();
  const uid = useAuthenticatedUID();
  const { page, setHasMore, hasMore, reset } = useInfiniteScrollContext();

  useEffect(() => {
    dispatch(quotesActions.resetQuotesCollection());
  }, []);

  useEffect(() => {
    if (!uid) return;
    dispatch(loadAllPublicQuotesAction());
  }, [uid, page]);

  useEffect(() => setHasMore(!allQuotesLoaded), [allQuotesLoaded]);

  useEffect(() => reset(), []);

  return (
    <Container maxWidth={'md'} className={classes.root}>
      <PageTitle text={'Discover Quotes'} />
      <QuotesList quotes={quotes} />

      {!hasMore && (
        <Box className={classes.listEnd}>
          <Typography variant={'subtitle2'}>
            Well Done! You&apos;ve scrolled to the end!
          </Typography>
        </Box>
      )}

      {hasMore && (
        <Box className={classes.loaderHolder}>
          <CircularProgress color="secondary" disableShrink />
        </Box>
      )}
    </Container>
  );
};
