import Box from '@material-ui/core/Box/Box';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC, useMemo } from 'react';

import { Tag } from '../../components/Tag';
import { useGlobalTags } from '../../redux/selectors';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginBottom: theme.spacing(1)
    },
    chip: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(1)
    }
  })
);

interface DigestScheduleViewTagsProps {
  selectedIds: string[];
}

export const DigestScheduleViewTags: FC<DigestScheduleViewTagsProps> = ({
  selectedIds
}) => {
  const classes = useStyles();

  const tags = useGlobalTags();

  const selectedTags = useMemo(() => {
    return tags.filter(tag => selectedIds.includes(tag.id as string));
  }, [selectedIds, tags.length]);

  return (
    <Box className={classes.root}>
      {selectedTags.map(({ id, label }) => {
        return (
          <Tag
            size={'small'}
            key={id}
            className={classes.chip}
            isSelected={true}
            label={label}
          />
        );
      })}
    </Box>
  );
};
