// TODO (critical): get rid of unstable.
//      see https://github.com/mui-org/material-ui/issues/13394
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import { blueGrey, lime } from '@material-ui/core/colors';

export const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: blueGrey[900]
    },
    secondary: {
      main: lime['A700']
    }
  }
});
