import { DigestSchedulesService } from '../../services';
import { AppThunk } from '../store';
import { digestActions } from './digestSlice';

export const deleteDigestScheduleAction = (
  id: string
): AppThunk => async dispatch => {
  dispatch(digestActions.setScheduleItemLoading({ id, loading: true }));

  await DigestSchedulesService.deleteSchedule(id);

  dispatch(digestActions.removeSchedule({ id }));
  dispatch(digestActions.setScheduleItemLoading({ id, loading: false }));
};
