import difference from 'lodash/difference';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';

import { QuotesService, QuotesToTagsService } from '../../services';
import { quotesActions } from '../quotes';
import { quotesToTagsSlice } from '../quotesToTags';
import { AppThunk } from '../store';

export const loadMoreTagsQuotesAction = (
  uid: string,
  selectedTags: string[]
): AppThunk => async (dispatch, getState) => {
  const state = getState();

  const tagsToLoad = selectedTags.filter(
    tag => !state.quotesToTags.allLoaded[tag]
  );

  if (tagsToLoad.length === 0) return;

  // get oldest loaded timestamps for each not fully loaded tag
  const timestamps = tagsToLoad.map(
    tag => state.quotesToTags.lastTimestamps[tag] || null
  );

  const lastTimestamp = timestamps.includes(null)
    ? new Date()
    : (sortBy(timestamps.map(ts => new Date(ts as string))).pop() as Date);

  dispatch(
    quotesToTagsSlice.actions.setLoading({
      tags: tagsToLoad,
      loading: true
    })
  );

  const quotesToTags = await QuotesToTagsService.findByTags(
    { tags: tagsToLoad, uid },
    { lastTimestamp }
  );

  // figure out what tags are completely loaded
  const receivedTags = uniq(quotesToTags.map(item => item.tag));
  const allLoadedTags = difference(tagsToLoad, receivedTags);

  // write all loaded tags to state
  if (allLoadedTags.length) {
    dispatch(
      quotesToTagsSlice.actions.setAllLoaded({
        loaded: true,
        tags: allLoadedTags
      })
    );
  }

  // get quotes
  const quoteIds = quotesToTags.map(item => item.quote);
  const quotes = await QuotesService.getQuotesByIdsList(quoteIds);

  // calculate new oldest timestamps
  const quotesToTagsByTag = groupBy(quotesToTags, 'tag');

  // create oldest loaded timestamp by tag
  const newTimestamps: Record<string, string> = mapValues(
    quotesToTagsByTag,
    items => {
      // order items by quote timestamp, get last item, pick quoteTimestamp from it
      const oldestItem = orderBy(items, i => i.quoteTimestamp, 'desc').pop();
      return oldestItem?.quoteTimestamp as string; // we know it exists
    }
  );

  // update the rest of the state
  dispatch(quotesActions.acceptData(quotes));
  dispatch(quotesToTagsSlice.actions.acceptItems(quotesToTags));
  dispatch(quotesToTagsSlice.actions.setLastTimestamps(newTimestamps));
  dispatch(
    quotesToTagsSlice.actions.setLoading({
      tags: tagsToLoad,
      loading: false
    })
  );
};
