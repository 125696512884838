import { authActions } from '../auth';
import { quotesActions } from '../quotes';
import { quotesListsActions } from '../quotesLists';
import { AppThunk } from '../store';
import { tagsActions } from '../tags';
import { viewActions } from '../view';

export const resetStoreAction = (): AppThunk => dispatch => {
  dispatch(viewActions.reset());
  dispatch(quotesActions.reset());
  dispatch(quotesListsActions.reset());
  dispatch(authActions.reset());
  dispatch(tagsActions.reset());
};
