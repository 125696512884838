import { tagsCollectionRef } from '../firebase/collection-refs';
import {
  firestoreTimeStampNow,
  firestoreToPlainObject,
  firestoreToPlainObjectMap
} from '../firebase/utils';
import { ITag } from '../model';

type CreateTagData = Pick<ITag, 'label'>;

export class TagsService {
  static async getGlobalTags(): Promise<ITag[]> {
    const querySnapshot = await tagsCollectionRef.get();
    return firestoreToPlainObjectMap(querySnapshot.docs);
  }

  static async updateTag(
    id: string,
    data: Partial<Pick<ITag, 'label'>>
  ): Promise<void> {
    await tagsCollectionRef.doc(id).update(data);
  }

  static async createGlobalTag(data: CreateTagData): Promise<ITag> {
    const reference = await tagsCollectionRef.add({
      ...data,
      timestamp: firestoreTimeStampNow()
    });

    const snap = await reference.get();

    return firestoreToPlainObject(snap);
  }

  static async removeGlobalTag(id: string): Promise<void> {
    await tagsCollectionRef.doc(id).delete();
  }
}
