import { IDigestSchedule } from '../../model';
import { DigestSchedulesService } from '../../services';
import { AppThunk } from '../store';
import { digestActions } from './digestSlice';

export const updateDigestScheduleAction = (
  id: string,
  data: Partial<IDigestSchedule>
): AppThunk => async dispatch => {
  if (data.id) delete data.id;

  dispatch(digestActions.setScheduleItemLoading({ id, loading: true }));

  await DigestSchedulesService.updateSchedule(id, data);

  dispatch(digestActions.updateSchedule({ id, data }));
  dispatch(digestActions.setScheduleItemLoading({ id, loading: false }));
};
