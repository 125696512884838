import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IQuoteToTag } from '../../model';
import {
  acceptCollectionStateData,
  createCollectionState,
  ICollectionState,
  removeFromCollectionState
} from '../utils';

interface QuotesToTagsSliceState {
  /** oldest item timestamp loaded by tag id */
  lastTimestamps: Record<string, string>;
  loading: Record<string, boolean>;
  allLoaded: Record<string, boolean>;
  collection: ICollectionState<IQuoteToTag>;
}

const initialState: QuotesToTagsSliceState = {
  lastTimestamps: {},
  loading: {},
  allLoaded: {},
  collection: createCollectionState<IQuoteToTag>()
};

interface SetLoadingPayload {
  tags: string[];
  loading: boolean;
}

/** latest timestamp by tag id */
type SetLastTimestampsPayload = Record<string, string>;

interface SetAllLoadedPayload {
  tags: string[];
  loaded: boolean;
}

export const quotesToTagsSlice = createSlice({
  name: 'quotesToTags',
  initialState,
  reducers: {
    resetLoaded(state) {
      state.collection = initialState.collection;
      state.allLoaded = initialState.allLoaded;
      state.lastTimestamps = initialState.lastTimestamps;
      state.loading = initialState.loading;
    },

    acceptItems(state, action: PayloadAction<IQuoteToTag[]>) {
      acceptCollectionStateData(state.collection, action.payload, {
        sortingOrder: 'desc',
        sortingKey: 'quoteTimestamp'
      });
    },

    removeById(state, action: PayloadAction<string>) {
      removeFromCollectionState(state.collection, action.payload);
    },

    setLastTimestamps(state, action: PayloadAction<SetLastTimestampsPayload>) {
      Object.assign(state.lastTimestamps, action.payload);
    },

    setLoading(state, action: PayloadAction<SetLoadingPayload>) {
      const { tags, loading } = action.payload;
      tags.map(tag => (state.loading[tag] = loading));
    },

    setAllLoaded(state, action: PayloadAction<SetAllLoadedPayload>) {
      const { tags, loaded } = action.payload;
      tags.map(tag => (state.allLoaded[tag] = loaded));
    }
  }
});
