import firebase from 'firebase/app';

import { AppThunk } from '../store';
import { authActions } from './authSlice';

export const loadUserRoleAction = (
  user: firebase.User
): AppThunk => async dispatch => {
  const tokenResult = await user.getIdTokenResult(true);
  const isAdmin = tokenResult.claims.admin === true;
  dispatch(authActions.setIsAdmin(isAdmin));
};
