import { useSelector } from 'react-redux';
import { ITag } from 'src/model';

import { RootState } from '../rootReducer';
import { getCollectionItems } from '../utils';

export const useGlobalTags = (): ITag[] => {
  return useSelector<RootState, ITag[]>(state =>
    getCollectionItems(state.tags.globalTagsCollection)
  );
};
