import { useEffect, useState } from 'react';

import { useSelectedTags } from '../../redux/selectors';
import { useQuotesToTagsAllLoaded } from '../../redux/selectors/quotesToTags';

export const useHasMoreToLoad = (): boolean => {
  const selectedTagsIds = useSelectedTags();
  const allLoadedTags = useQuotesToTagsAllLoaded();

  const [hasMore, setHasMore] = useState<boolean>(true);

  useEffect(() => {
    const notLoaded = selectedTagsIds
      .map(tag => allLoadedTags[tag])
      .filter(loaded => !loaded);

    setHasMore(!!notLoaded.length);
  }, [allLoadedTags, selectedTagsIds.concat('')]);

  return hasMore;
};
