/* eslint-disable max-len,no-console */
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useLayoutEffect,
  useRef,
  useState
} from 'react';

interface UseInfiniteScrollOptions {
  containerRef?: MutableRefObject<HTMLElement | null> | null;
  distance?: number;
}

interface UseInfiniteScrollReturn {
  scrollContainerRef: MutableRefObject<HTMLElement | null>;
  loaderRef: MutableRefObject<HTMLElement | null>;
  page: number;
  hasMore: boolean;
  setHasMore: Dispatch<SetStateAction<boolean>>;
  reset: () => void;
}

type UseInfiniteScrollHook = (
  options?: UseInfiniteScrollOptions
) => UseInfiniteScrollReturn;

export const useInfiniteScroll: UseInfiniteScrollHook = ({
  distance = 30
} = {}) => {
  const scrollContainerRef = useRef<HTMLElement>(null);
  const loaderRef = useRef<HTMLElement>(null);

  const [hasMore, setHasMore] = useState<boolean>(true);
  const [page, setPage] = useState(0);

  const reset = useCallback(() => {
    setHasMore(true);
    setPage(0);
  }, []);

  useLayoutEffect(() => {
    const loaderNode = loaderRef.current;
    const scrollContainerNode = scrollContainerRef.current;

    if (!scrollContainerNode || !loaderNode || !hasMore) return;

    const options = {
      root: scrollContainerNode,
      rootMargin: `0px 0px ${distance}px 0px`
    };

    let prevY: number;
    let prevRatio = 0;

    const listener: IntersectionObserverCallback = entries => {
      entries.forEach(
        ({ isIntersecting, intersectionRatio, boundingClientRect = {} }) => {
          const { y = 0 } = boundingClientRect;

          if (
            isIntersecting &&
            intersectionRatio >= prevRatio &&
            (!prevY || y < prevY)
          ) {
            console.log('updating page');
            setPage(page => page + 1);
          }
          prevY = y;
          prevRatio = intersectionRatio;
        }
      );
    };

    const observer = new IntersectionObserver(listener, options);
    observer.observe(loaderNode);

    return () => observer.disconnect();
  }, [hasMore, distance]);

  return { page, loaderRef, scrollContainerRef, hasMore, setHasMore, reset };
};
