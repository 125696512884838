import Box from '@material-ui/core/Box/Box';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Container from '@material-ui/core/Container/Container';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography/Typography';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { quotesActions } from 'src/redux/quotes';

import { useInfiniteScrollContext } from '../../components/InfiniteScroll';
import { PageTitle } from '../../components/PageTitle';
import { QuotesList } from '../../components/QuotesList';
import { loadMoreTagsQuotesAction } from '../../redux/commonActions';
import {
  useAuthenticatedUID,
  useSelectedTags,
  useTaggedQuotes
} from '../../redux/selectors';
import { TagsCloud } from './TagsCloud';
import { useContentLoading } from './useContentLoading';
import { useHasMoreToLoad } from './useHasMoreToLoad';

const useStyles = makeStyles(theme =>
  createStyles({
    titleRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    listEnd: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    loaderHolder: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  })
);
interface TagsPageProps {}

export const TagsPage: FC<TagsPageProps> = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const uid = useAuthenticatedUID();
  const taggedQuotes = useTaggedQuotes();
  const selectedTagsIds = useSelectedTags();

  const hasMoreToLoad = useHasMoreToLoad();
  const loading = useContentLoading();

  const { page, setHasMore, hasMore, reset } = useInfiniteScrollContext();

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log({ hasMoreToLoad });
    if (!uid || !selectedTagsIds.length) return;
    if (!hasMoreToLoad) return;
    dispatch(loadMoreTagsQuotesAction(uid, selectedTagsIds));
  }, [uid, selectedTagsIds.concat(''), page, hasMoreToLoad]);

  useEffect(() => {
    if (hasMoreToLoad) reset();
    setHasMore(hasMoreToLoad);
  }, [hasMoreToLoad]);

  useEffect(() => {
    dispatch(quotesActions.resetQuotesCollection());
  }, []);

  return (
    <Container maxWidth={'md'}>
      <PageTitle text={'My Tags'} />
      <TagsCloud />
      <QuotesList quotes={taggedQuotes} />

      {!hasMore && (
        <Box className={classes.listEnd}>
          <Typography variant={'subtitle2'}>
            Well Done! You&apos;ve scrolled to the end!
          </Typography>
        </Box>
      )}

      {hasMoreToLoad && loading && (
        <Box className={classes.loaderHolder}>
          <CircularProgress color="secondary" disableShrink />
        </Box>
      )}
    </Container>
  );
};
