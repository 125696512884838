import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { APP_ROUTES_CONFIG } from './appRoutesConfig';
import { AuthPage } from './pages/Auth';
import { DiscoverPage } from './pages/Discover';
import { MyListPage } from './pages/MyList';
import { SettingsPage } from './pages/Settings';
import { TagsPage } from './pages/Tags';
import {
  useIsAuthenticated,
  useIsAuthLoaded,
  useSelectedListId
} from './redux/selectors';

interface AppRoutesProps {}

export const AppRoutes: FC<AppRoutesProps> = () => {
  const isAuthenticated = useIsAuthenticated();
  const isAuthLoaded = useIsAuthLoaded();
  const selectedListId = useSelectedListId();

  if (!isAuthLoaded) return null;

  const appDataLoaded = !!selectedListId;
  if (isAuthenticated && !appDataLoaded) return null;

  return (
    <Switch>
      <Route path={APP_ROUTES_CONFIG.AUTH}>
        <AuthPage />
      </Route>

      <Route path={APP_ROUTES_CONFIG.DISCOVER}>
        <DiscoverPage />
      </Route>

      <Route path={APP_ROUTES_CONFIG.LIST}>
        <MyListPage />
      </Route>

      <Route path={APP_ROUTES_CONFIG.TAGS}>
        <TagsPage />
      </Route>

      <Route path={APP_ROUTES_CONFIG.SETTINGS}>
        <SettingsPage />
      </Route>

      {isAuthenticated && <Redirect to={APP_ROUTES_CONFIG.DISCOVER} />}

      {!isAuthenticated && <Redirect to={APP_ROUTES_CONFIG.AUTH} />}
    </Switch>
  );
};
