import difference from 'lodash/difference';

import { QuotesToListsService } from '../../services';
import { quotesActions } from '../quotes';
import { AppThunk } from '../store';

export const checkLikedStateAction = (quoteIds: string[]): AppThunk => async (
  dispatch,
  getState
) => {
  const state = getState();

  const uid = state.auth.user?.uid;

  if (!uid) return;

  const loaded = Object.keys(state.quotes.liked);
  const notLoaded = difference(quoteIds, loaded);

  const addedState = await QuotesToListsService.checkAddedState(uid, notLoaded);

  dispatch(quotesActions.setQuoteLikedStateMultiple(addedState));
};
