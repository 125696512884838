import { IModelBase } from '../../model';
import { ICollectionState } from './ICollectionState';

export const setCollectionItemLoading = <T extends IModelBase>(
  collection: ICollectionState<T>,
  id: string,
  isLoading: boolean
): void => {
  if (isLoading && !collection.loadingIds.includes(id)) {
    collection.loadingIds.push(id);
  }

  const index = collection.loadingIds.indexOf(id);
  if (index !== -1) collection.loadingIds.splice(index, 1);
};
