import { useContext } from 'react';

import {
  InfiniteScrollContext,
  InfiniteScrollContextValue
} from './InfiniteScrollContext';

export const useInfiniteScrollContext = (): InfiniteScrollContextValue => {
  return useContext(InfiniteScrollContext);
};
